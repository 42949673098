import React from 'react'
import tw, { styled, theme } from 'twin.macro'
import { NavLink } from 'react-router-dom'

type WrapperProps = {
  selected?: boolean
}

const Wrapper = styled(NavLink)<WrapperProps>`
  ${tw`text-sm relative block`}

  &::after {
    ${tw`absolute left-0 h-[1px] rounded-md transition-all duration-500 ease-in-out`}
    content: "";
    background: linear-gradient(
      to right,
      ${theme('colors.electricViolet')},
      ${theme('colors.vividBlue')}
    );
    bottom: -7px;
    width: 0;
    ${({ selected }) => selected && tw`w-full`}
  }

  &:hover::after {
    ${({ selected }) => !selected && tw`w-full`}
  }
`

type NavigationLinkProps = {
  to: string
  label: string
  selected?: boolean
}
export const NavigationLink = ({ to, label, selected }: NavigationLinkProps) => {
  return (
    <Wrapper to={to} selected={selected}>
      {label}
    </Wrapper>
  )
}
