import React, { useEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Inline, Stack } from '../../common/components/Spacing'
import { Description, SubTitle } from '../../common/styled'
import { Alignments, DESKTOP_ANIMATION_SHIFT } from '../../common/constants'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import {
  useIsMobile,
  useRelativeScrollPosition,
  useVerticalScrollWithThrottle,
} from '../../common/hooks'
import { getCurrentLocale } from '../../../config/locales'
import { prismicClient } from '../../../config/prismicClient'
import { useParams } from 'react-router-dom'

const Wrapper = tw(motion.div)`mt-[200px] h-[600px] ml-[70px]`
const HubImage = tw.img` h-[600px]`

const MobileWrapper = tw.div`mb-[90px]`
const MobileHub = tw(motion.img)`w-[610px] mb-[50px] ml-[100px] mt-[80px]`

const MobileSubTitle = tw(SubTitle)`text-[32px] leading-[110.5%]`
const MobileDescription = tw(Description)`text-[14px]`

// Mobile
const START_POSITION = 140
const END_POSITION = -400
const SMOOTHNESS = 2
const VERTICAL_SHIFT_COEFFICIENT = 4
const RELATIVE_SCROLL_THRESHOLD = 20

const MobileAppWrapper = styled.div`
  transform: translateX(${START_POSITION}px);
`

// Desktop
const DESKTOP_IMAGE_SCALE_START = 1.4
const DESKTOP_IMAGE_SCALE_END = 1
const DESKTOP_IMAGE_HORIZONTAL_SHIFT_START = -395
const DESKTOP_IMAGE_HORIZONTAL_SHIFT_END = 0
const LEFT_WRAPPER_WIDTH = 520

const LeftWrapper = styled(motion.div)`
  width: ${LEFT_WRAPPER_WIDTH}px;
`

const HubTitle = styled(SubTitle)`
  width: ${LEFT_WRAPPER_WIDTH}px;
`

const RightWrapper = styled(motion.div)`
  //margin-left: ${DESKTOP_IMAGE_HORIZONTAL_SHIFT_START}px;
`

type HubSubscriptionsProps = {
  isReady?: boolean
}

export const HubSubscriptions = ({ isReady }: HubSubscriptionsProps) => {
  const isMobile = useIsMobile()
  const { locale } = useParams()
  const imageRef = useRef<HTMLElement | null>(null)

  const [imgAppeared, setImgAppeared] = useState(false)
  const [imgFullyVisible, setImgFullyVisible] = useState(false)
  const [imgStartY, setImgStartY] = useState(0)
  const [imgIsScrolled, setImgIsScrolled] = useState(false)

  const { scrollY: hubScrollY } = useScroll()
  const { scrollY } = useVerticalScrollWithThrottle()
  const relativeScrollY = useRelativeScrollPosition(imageRef.current)

  /* Mobile */
  const mobileHubControls = useAnimation()
  const [hubRef, hubInView] = useInView({ threshold: 0.3 })

  const titleControls = useAnimation()
  const [titleRef, titleRefInView] = useInView({ threshold: 0.3 })

  const descriptionControls = useAnimation()

  /* Desktop */
  const wrapperControls = useAnimation()
  const leftWrapperControls = useAnimation()
  const rightWrapperControls = useAnimation()

  const leftWrapperRef = useRef<HTMLDivElement>(null)
  const rightWrapperRef = useRef<HTMLDivElement>(null)

  const desktopScale = useTransform(
    hubScrollY,
    [1200, 1650],
    [DESKTOP_IMAGE_SCALE_START, DESKTOP_IMAGE_SCALE_END],
    { clamp: false },
  )

  const [isStopped, setIsStopped] = useState(false)
  const [prismicData, setPrismicData] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const productItemId =
        locale === 'en'
          ? 'ZiomkhAAABQfZ-Tl'
          : locale === 'fi'
            ? 'ZiommBAAAPAeZ-UE'
            : 'ZiomoRAAABggZ-Ux'

      const data =
        await prismicClient.getByID(productItemId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])

  // show hub image and start animation
  useEffect(() => {
    const sequence = async () => {
      if (!imgAppeared) {
        await mobileHubControls.start({
          opacity: 1,
          transition: { duration: 0.3, delay: 0.4 },
        })
        setImgAppeared(true)
        return
      }

      if (relativeScrollY <= RELATIVE_SCROLL_THRESHOLD) {
        if (imgStartY === 0) {
          setImgStartY(scrollY)
        }
        setImgFullyVisible(true)
      }
    }

    if (hubInView && isMobile) {
      sequence()
    }
  }, [hubInView, mobileHubControls, isMobile, scrollY, relativeScrollY])

  // handle hub image horizontal and vertical movement
  useEffect(() => {
    const handleScroll = () => {
      if (!isMobile) {
        return
      }
      if (imgFullyVisible) {
        const xPosition = SMOOTHNESS * (START_POSITION - (scrollY - imgStartY))
        if (xPosition >= END_POSITION && xPosition <= START_POSITION) {
          if (imageRef.current) {
            imageRef.current.style.transform = `translateX(${xPosition}px)`
            imageRef.current.style.marginTop = `${(START_POSITION - xPosition) / VERTICAL_SHIFT_COEFFICIENT}px`
          }
        }

        setImgIsScrolled(xPosition < END_POSITION)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [isMobile, hubInView, imgFullyVisible, scrollY])

  useEffect(() => {
    const hubDescriptionSequence = async () => {
      await titleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await descriptionControls.start({ opacity: 1, transition: { duration: 0.3 } })
    }
    if (isMobile && titleRefInView && imgIsScrolled) {
      hubDescriptionSequence()
    }
  }, [isMobile, titleRefInView, imgIsScrolled])

  useEffect(() => {
    const desktopSequence = async () => {
      await wrapperControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.6 } })
    }
    if (!isMobile) {
      desktopSequence()
    }
  }, [isMobile, wrapperControls])

  useEffect(() => {
    const sequence = async () => {
      const scale =
        desktopScale.get() > DESKTOP_IMAGE_SCALE_END
          ? desktopScale.get() > DESKTOP_IMAGE_SCALE_START
            ? DESKTOP_IMAGE_SCALE_START
            : desktopScale.get()
          : DESKTOP_IMAGE_SCALE_END
      rightWrapperControls.start({
        scale,
      })

      // get intermediate horizontal shift of the right wrapper with interpolation function:
      // y = y0 + (x - x0) * ((y1 - y0) / (x1 - x0))
      // In this case:
      // y0 = DESKTOP_IMAGE_VERTICAL_SHIFT_START
      // x0 = DESKTOP_IMAGE_SCALE_START
      // y1 = DESKTOP_IMAGE_VERTICAL_SHIFT_END
      // x1 = DESKTOP_IMAGE_SCALE_END
      // x = scale
      // hence:

      // same logic for margin left shift
      const marginLeftShift =
        DESKTOP_IMAGE_HORIZONTAL_SHIFT_START +
        (scale - DESKTOP_IMAGE_SCALE_START) *
          ((DESKTOP_IMAGE_HORIZONTAL_SHIFT_END - DESKTOP_IMAGE_HORIZONTAL_SHIFT_START) /
            (DESKTOP_IMAGE_SCALE_END - DESKTOP_IMAGE_SCALE_START))

      if (imageRef.current) {
        imageRef.current.style.marginLeft = `${marginLeftShift}px`
      }

      if (scale === DESKTOP_IMAGE_SCALE_END) {
        if (!isStopped) {
          document.body.style.overflowY = 'hidden'
          setTimeout(() => {
            document.body.style.overflowY = 'auto'
            setIsStopped(true)
          }, 500)
        }
        leftWrapperControls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 0.3, ease: 'linear' },
        })
      } else {
        leftWrapperControls.start({
          opacity: 0,
          y: DESKTOP_ANIMATION_SHIFT,
          transition: { duration: 0.1, ease: 'linear' },
        })
      }
    }

    if (!isMobile) {
      sequence()
    }
  }, [isMobile, rightWrapperControls, scrollY])

  const setRefs = (node: HTMLElement | null) => {
    imageRef.current = node
    hubRef(node)
  }

  if (isMobile) {
    return (
      <MobileWrapper>
        {/* Wrapped because of conflict between initial 'x' position and hubInView:
                  initial 'x' leads hubInView to become true too late */}
        <MobileAppWrapper ref={setRefs}>
          <MobileHub
            ref={hubRef}
            animate={mobileHubControls}
            initial={{ opacity: 0 }}
            src="/images/hub_subscriptions.png"
          />
        </MobileAppWrapper>
        <Stack gap={7.5}>
          <MobileSubTitle ref={titleRef} animate={titleControls} initial={{ opacity: 0 }}>
            {prismicData?.data.product_header[0]?.text}
          </MobileSubTitle>
          <MobileDescription animate={descriptionControls} initial={{ opacity: 0 }}>
            {prismicData?.data.product_description[0]?.text}
          </MobileDescription>
        </Stack>
      </MobileWrapper>
    )
  }
  return (
    <Wrapper
      ref={setRefs}
      animate={wrapperControls}
      initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}
    >
      <Inline verticalalign={Alignments.Center} align={Alignments.Left} gap={20}>
        <LeftWrapper
          ref={leftWrapperRef}
          animate={leftWrapperControls}
          initial={{ y: DESKTOP_ANIMATION_SHIFT, opacity: 0 }}
        >
          <Stack gap={7.5} align={Alignments.Left} width="100%">
            <HubTitle>{prismicData?.data.product_header[0]?.text}</HubTitle>
            <Stack gap={10} width="100%">
              <Description>{prismicData?.data.product_description[0]?.text}</Description>
            </Stack>
          </Stack>
        </LeftWrapper>
        <RightWrapper
          ref={rightWrapperRef}
          animate={rightWrapperControls}
          initial={{ scale: DESKTOP_IMAGE_SCALE_START }}
        >
          <Inline gap={0}>
            <HubImage src="/images/hub_subscriptions.png" />
          </Inline>
        </RightWrapper>
      </Inline>
    </Wrapper>
  )
}
