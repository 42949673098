import React from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Alignments } from '../../constants'

type StackProps = {
  children: React.ReactNode
  align?: Alignments
  verticalAlign?: Alignments
  gap?: number
  width?: string
  ref?: any
}

type StackWrapperProps = {
  align?: Alignments
  verticalAlign?: Alignments
  gap?: number
  width?: string
}

const StackWrapper = styled.div<StackWrapperProps>`
  ${tw`flex flex-col gap-2`}
  ${({ align }) =>
    align === Alignments.Left
      ? tw`items-start`
      : align === Alignments.Center
        ? tw`items-center`
        : align === Alignments.Right
          ? tw`items-end`
          : ''}
  
${({ verticalAlign }) =>
    verticalAlign === Alignments.Left
      ? tw`justify-start`
      : verticalAlign === Alignments.Center
        ? tw`justify-center`
        : verticalAlign === Alignments.Right
          ? tw`justify-end`
          : verticalAlign === Alignments.Between
            ? tw`justify-between h-[100%]`
            : ''}
  
  ${({ gap }) =>
    (gap || gap === 0) &&
    css`
    gap: calc(${theme`spacing.1`} * ${gap});
  }
  `}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
`

export const Stack = ({ children, align, verticalAlign, gap, width, ref }: StackProps) => {
  return (
    <StackWrapper align={align} gap={gap} style={{ width }} verticalAlign={verticalAlign} ref={ref}>
      {children}
    </StackWrapper>
  )
}
