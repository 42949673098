import React, { createContext, useState, ReactNode, useContext } from 'react'

interface BookDemoModalContextProps {
  bookDemoModalOpen: boolean
  setBookDemoModalOpen: (open: boolean) => void
}

// Creating the context without a default value to enforce its usage within a provider
export const BookDemoModalContext = createContext<BookDemoModalContextProps | undefined>(undefined)

// Custom hook for safe context consumption
export const useBookDemoModal = () => {
  const context = useContext(BookDemoModalContext)
  if (context === undefined) {
    throw new Error('useBookDemoModal must be used within a BookDemoModalProvider')
  }
  return context
}

interface BookDemoModalProviderProps {
  children: ReactNode
}

export const BookDemoModalProvider: React.FC<BookDemoModalProviderProps> = ({ children }) => {
  const [bookDemoModalOpen, setBookDemoModalOpen] = useState(false)

  return (
    <BookDemoModalContext.Provider value={{ bookDemoModalOpen, setBookDemoModalOpen }}>
      {children}
    </BookDemoModalContext.Provider>
  )
}
