import React from 'react'
import tw, { styled, theme } from 'twin.macro'
import { Stack } from '../Spacing'

const Wrapper = styled.div``

type StyledInputProps = {
  isError?: boolean
}
const StyledInput = styled.input<StyledInputProps>`
  padding: 10px 15px;
  border: none;
  height: 41px;
  align-items: center;
  align-self: stretch;
  border-radius: 7px;
  background: ${theme('colors.zevoyGray1')};
  font-size: 16px;
  color: ${theme('colors.zevoyWhite')};
  box-sizing: border-box;

  ${({ isError }: StyledInputProps) => isError && tw`border-1 border-zevoyNegative`}
  &:focus {
    outline: none;
    border-color: #007bff;
  }
  &:-webkit-autofill {
    background-color: ${theme('colors.zevoyGray1')} !important;
    color: ${theme('colors.zevoyWhite')} !important;
  }
  &::placeholder {
    color: ${theme('colors.zevoyGray3')};
  }

  /* Remove spinner buttons for Chrome, Safari, Edge, Opera */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 0;
    pointer-events: none;
    margin: 0;
  }

  /* Remove spinner buttons for Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`

export const ErrorMessage = tw.div`text-zevoyNegative text-sm`

type TextInputProps = {
  errorMessage?: string
} & React.InputHTMLAttributes<HTMLInputElement>

export const TextInput = ({
  placeholder,
  type = 'text',
  onChange,
  errorMessage,
}: TextInputProps) => {
  return (
    <Wrapper>
      <Stack gap={2}>
        <StyledInput
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          isError={!!errorMessage}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </Stack>
    </Wrapper>
  )
}
