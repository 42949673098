import React from 'react'
import { Inline, Stack } from '../../common/components/Spacing'
import {
  Alignments,
  ComponentVariants,
  DESKTOP_ANIMATION_SHIFT,
  Sizes,
} from '../../common/constants'
import { Button } from '../../common/components/Buttons'
import tw, { styled } from 'twin.macro'
import { NavLink, useParams } from 'react-router-dom'
import { useIsMobile } from '../../common/hooks'
import { AnimationControls, motion } from 'framer-motion'
import { LocaleIndex } from '../../common/types'

const Wrapper = tw.div`mt-[250px] text-center w-[800px] mx-auto relative z-10 `


type HeroTitleProps = {
  locale: string
}
const HeroTitle = styled.h1<HeroTitleProps>`
  ${tw`text-[84px] font-medium -tracking-[1.5px] m-0 leading-[93%]`}
  font-family: "Slussen-Medium", sans-serif;
  ${({ locale }) => locale === 'fi' && tw`leading-[1.2]`}
`
const HeroDescription = tw.h2`text-xl text-base font-light m-0`
const HeroButtons = tw.div``

type UnderlineProps = {
  locale: string
}
const Underline = styled(motion.div)<UnderlineProps>`
  ${tw`absolute top-[70px] h-[27px]`}
  background: url(/images/line-marker.png) no-repeat;
  background-size: contain;
  transform-origin: left center;
  ${({ locale }) => locale === 'en' && tw`left-[55px] w-[340px]`}
  ${({ locale }) => locale === 'fi' && tw`left-[145px] w-[500px] h-[40px] top-[80px]`}
  ${({ locale }) => locale === 'sv' && tw`left-[235px] w-[340px]`}
`

const MobileWrapper = tw.div`relative z-10 mt-[125px]`
const MobileHeroTitle = tw(HeroTitle)`text-[40px] leading-[105%]`
const MobileDescription = tw(HeroDescription)`text-[14px] leading-[1.5]`
const MobileUnderline = styled(motion.div)<UnderlineProps>`
  ${tw`absolute top-[36px] left-[77px] w-[169px] h-[11px]`}
  background: url(/images/line-marker.png) no-repeat;
  background-size: cover;
  transform-origin: left center;
  ${({ locale }) => locale === 'en' && tw`left-[0px] w-[145px]`}
  ${({ locale }) => locale === 'fi' && tw`-left-[5px] w-[215px] top-[36px] h-[14px]`}
  ${({ locale }) => locale === 'sv' && tw`left-[0px] w-[145px]`}
`

type HeroSectionProps = {
  mobileTitleControls?: AnimationControls
  mobileUnderlineControls?: AnimationControls
  mobileDescriptionControls?: AnimationControls
  wrapperControls?: AnimationControls
  underlineControls?: AnimationControls
  translations: {
    heroTitle: string
    heroDescription: string
    heroCta: string
  }
}

export const HeroSection = ({
  mobileTitleControls,
  mobileUnderlineControls,
  mobileDescriptionControls,
  wrapperControls,
  underlineControls,
  translations
}: HeroSectionProps) => {
  const { locale } = useParams()
  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack align={Alignments.Left} gap={5}>
          <motion.div animate={mobileTitleControls} initial={{ opacity: 0 }}>
            <MobileHeroTitle locale={locale as LocaleIndex}>
              <span>{translations.heroTitle}</span>
              <MobileUnderline
                locale={locale as LocaleIndex}
                animate={mobileUnderlineControls}
                initial={{ clipPath: 'inset(0 100% 0 0)' }}
              />
            </MobileHeroTitle>
          </motion.div>
          <motion.div animate={mobileDescriptionControls} initial={{ opacity: 0 }}>
            <Stack gap={10}>
              <MobileDescription>{translations.heroDescription}</MobileDescription>
              <HeroButtons>
                <Inline align={Alignments.Left}>
                  <NavLink to={`/${locale}/contact`}>
                    <Button variant={ComponentVariants.Primary} size={Sizes.Medium}>
                      {translations.heroCta}
                    </Button>
                  </NavLink>
                </Inline>
              </HeroButtons>
            </Stack>
          </motion.div>
        </Stack>
      </MobileWrapper>
    )
  }

  return (
    <motion.div animate={wrapperControls} initial={{ y: DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
      <Wrapper>
        <Stack align={Alignments.Center} gap={10}>
          <HeroTitle locale={locale as LocaleIndex}>
            <span>{translations.heroTitle}</span>
            <Underline locale={locale as LocaleIndex} animate={underlineControls} initial={{ clipPath: 'inset(0 100% 0 0)' }} />
          </HeroTitle>
          <Stack gap={7.5}>
            <HeroDescription>{translations.heroDescription}</HeroDescription>
            <HeroButtons>
              <Inline align={Alignments.Center}>
                <NavLink to={`/${locale}/contact`}>
                  <Button variant={ComponentVariants.Primary} size={Sizes.Medium}>
                    {translations.heroCta}
                  </Button>
                </NavLink>
              </Inline>
            </HeroButtons>
          </Stack>
        </Stack>
      </Wrapper>
    </motion.div>
  )
}
