import tw from 'twin.macro';
import React, { useEffect } from 'react';
import { Stack } from '../../common/components/Spacing';
import { Alignments, DESKTOP_ANIMATION_SHIFT } from '../../common/constants';
import { useIsMobile } from '../../common/hooks';
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { HeroTitle } from '../../common/styled'
import { useLocation } from 'react-router-dom'

const Wrapper = tw.div`
  mt-[180px] text-center w-[900px] mx-auto relative z-10
`;

const HeroTopTitle = tw.h1`text-xl text-[14px] font-light m-0`;
const DesktopHeroTitle = tw(HeroTitle)`leading-[98%]`;

const MobileWrapper = tw.div`
`;

const MobileHeroTitle = tw(HeroTitle)`text-[40px] leading-[105%] w-full`;

type HeroSectionProps = {
  setHeroSectionAppeared: (hasAppeared: boolean) => void;
  translations: {
    heroTopTitle: string;
    heroTitle: string;
  };
};

export const HeroSection = ({ setHeroSectionAppeared, translations }: HeroSectionProps) => {
  const isMobile = useIsMobile();
  const mobileTitleControls = useAnimation();
  const mobileDescriptionControls = useAnimation();
  const wrapperControls = useAnimation();
  const { pathname } = useLocation();

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await mobileTitleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.6 } });
      await mobileDescriptionControls.start({ opacity: 1, transition: { duration: 0.3 } });
      setHeroSectionAppeared(true);
    };

    const desktopHeroSequence = async () => {
      await wrapperControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } });
      setHeroSectionAppeared(true);
    };

    if (isMobile) {
      mobileHeroSequence();
    } else {
      desktopHeroSequence();
    }
  }, [isMobile, wrapperControls, pathname]);

  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack align={Alignments.Left} gap={5}>
          <motion.div animate={mobileTitleControls} initial={{ opacity: 0 }}>
            <Stack gap={5}>
              <HeroTopTitle>{translations.heroTopTitle}</HeroTopTitle>
              <MobileHeroTitle>{translations.heroTitle}</MobileHeroTitle>
            </Stack>
          </motion.div>
        </Stack>
      </MobileWrapper>
    );
  }

  return (
      <motion.div
        animate={wrapperControls} initial={{ y: DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
        <Wrapper>
          <Stack align={Alignments.Center} gap={10}>
            <Stack gap={7.5}>
              <HeroTopTitle>{translations.heroTopTitle}</HeroTopTitle>
              <DesktopHeroTitle>{translations.heroTitle}</DesktopHeroTitle>
            </Stack>
          </Stack>
        </Wrapper>
      </motion.div>
  );
};