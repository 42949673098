import React, { useEffect } from 'react'

import tw, { styled } from 'twin.macro'
import { TextHighlight } from '../../common/styled'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useWindowHeight } from '@react-hook/window-size'

const Wrapper = tw.div`relative`

type TitleProps = {
  isMobile?: boolean
}
const Title = styled(motion.div)<TitleProps>`
  ${tw`-tracking-[1.5px] font-medium leading-[93%]`}
  font-family: "Slussen-Medium", sans-serif;
  ${tw`w-[580px] text-[72px] text-left relative top-[160px] left-[60px] z-10`}
  ${({ isMobile }: TitleProps) => isMobile && tw`w-[304px] text-[42px] left-[23px] top-[400px]`}
`

const MobileWrapper = styled(motion.div)`
  background: url('/images/map_mobile.png') no-repeat center;
  background-size: cover;
  height: 570px;
  margin-top: -130px;
`

const DesktopWrapper = styled(motion.div)`
  background: url('/images/map.png') no-repeat center;
  background-size: cover;
  height: 700px;
`

type ZevoyMapProps = {
  translations: {
    title: string
    highlighted: string
  }
}
export const ZevoyMap = ({ translations }: ZevoyMapProps) => {
  const isMobile = useIsMobile()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`

  const mapControls = useAnimation()
  const [titleRef, titleRefInView] = useInView({
    threshold: 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })

  const titleControls = useAnimation()

  useEffect(() => {
    const sequence = async () => {
      await mapControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await titleControls.start('visible')
    }

    if (titleRefInView) {
      sequence()
    }
  }, [isMobile, titleRefInView, mapControls])

  const wordsPart1 = translations.title?.split(' ') ?? []
  const wordsHighlighting = translations.highlighted?.split(' ') ?? []
  const containerVariants = {
    visible: {
      opacity: 1,
      transition: {
        delay: 0.3,
        staggerChildren: 0.5,
      },
    },
    hidden: { opacity: 0 },
  }
  const childVariants = (i: number) => ({
    visible: {
      opacity: 1,
      x: 0,
      transition: { delay: i * 0.2, duration: 0.5, type: 'linear', stiffness: 100 },
    },
    hidden: { opacity: 0, x: -200 },
  })
  return (
    <Wrapper>
      <Title
        ref={titleRef}
        isMobile={isMobile}
        initial="hidden"
        animate={titleControls}
        variants={containerVariants}
      >
        {isMobile ? (
          <>
            {wordsPart1.map((word, index) => (
              <motion.span key={index} variants={childVariants(index)}>
                {word}{' '}
              </motion.span>
            ))}
            {wordsHighlighting.map((word, index) => (
              <TextHighlight key={index} variants={childVariants(wordsPart1.length)}>
                {word}{' '}
              </TextHighlight>
            ))}
          </>
        ) : (
          <span>
            {translations.title}{' '}
            <TextHighlight>{translations.highlighted}</TextHighlight>
          </span>
        )}
      </Title>
      {isMobile ? (
        <MobileWrapper animate={mapControls} initial={{ opacity: 0 }} />
      ) : (
        <DesktopWrapper animate={mapControls} initial={{ opacity: 0 }} />
      )}
    </Wrapper>
  )
}
