import React, { useEffect, useState } from 'react'
import { Navigation } from '../Navigation'
import { HeroSection } from './components/HeroSection'
import { Card } from './components/Card'
import { AppIntro } from './components/AppIntro'
import { Hub } from './components/Hub'
import { TransparentPricing } from './components/TransparentPricing'
import { Stack } from '../common/components/Spacing'
import { ContactUs } from './components/ContactUs'
import { SimplestSolution } from '../SimplestSolution'
import { ThingsToKnow } from './components/ThingsToKnow'
import { Footer } from '../Footer'
import { MWrapper } from '../common/styled'
import { useIsMobile } from '../common/hooks'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { useParams } from 'react-router-dom'

export const Expenses = () => {
  const isMobile = useIsMobile()
  const { locale } = useParams<string>()
  const [prismicData, setPrismicData] = useState<any>(null)
  const [heroSectionAppeared, setHeroSectionAppeared] = useState(false)
  const [cardSectionAppeared, setCardSectionAppeared] = useState(false)
  const [appIntroSectionAppeared, setAppIntroSectionAppeared] = useState(false)

  const showAppIntro = heroSectionAppeared && cardSectionAppeared
  const showHub = heroSectionAppeared && cardSectionAppeared && appIntroSectionAppeared

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const pageId =
        locale === 'en'
          ? 'ZiZ7RBAAAOy0V6CA'
          : locale === 'fi'
            ? 'ZiZ7SBAAAA61V6CX'
            : 'ZiZ7TxAAAJu0V6C7'

      const data =
        await prismicClient.getByID(pageId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])
  return (
    <div>
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={25}>
          <HeroSection
            setHeroSectionAppeared={setHeroSectionAppeared}
            translations={{
              heroTitle: prismicData?.data.product_page_header[0]?.text,
              heroDescription: prismicData?.data.product_page_description[0]?.text,
              heroCta: prismicData?.data.product_page_cta[0]?.text,
            }} />
          <div>
            <Card setAppeared={setCardSectionAppeared} />
            <AppIntro isReady={showAppIntro} setAppeared={setAppIntroSectionAppeared} />
            <Hub isReady={showHub} />
          </div>
          <TransparentPricing
            translations={{
              title: prismicData?.data.pricing_header[0]?.text,
              description: prismicData?.data.pricing_description[0]?.text,
              option1: prismicData?.data.pricing_row_1[0]?.text,
              price1: prismicData?.data['pricing_row_1.2'][0]?.text,
              option2: prismicData?.data.pricing_row_2[0]?.text,
              price2: prismicData?.data['pricing_row_2.2'][0]?.text,
              option3: prismicData?.data.pricing_row_3[0]?.text,
              price3: prismicData?.data['pricing_row_3.2'][0]?.text,
              active_user_disclaimer: prismicData?.data.pricing_disclaimer[0]?.text,
              cta: prismicData?.data.pricing_cta[0]?.text,
            }}
          />
          <ContactUs />
          <ThingsToKnow />
          <SimplestSolution shouldStartAnimation={heroSectionAppeared} />
        </Stack>
        <Footer />
      </MWrapper>
    </div>
  )
}
