import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Navigation } from '../Navigation'
import { Stack } from '../common/components/Spacing'
import { HeroSection } from './components/HeroSection'
import { SimplestSolution } from '../SimplestSolution'
import { Footer } from '../Footer'
import { MWrapper } from '../common/styled'
import { useIsMobile } from '../common/hooks'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'

export const Contact = () => {
  const { locale } = useParams<string>()
  const isMobile = useIsMobile()
  const [heroSectionAppeared, setHeroSectionAppeared] = useState(false)
  const [prismicData, setPrismicData] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const data =
        await prismicClient.getSingle('contact_page', {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])

  if (!prismicData) {
    return null
  }
  return (
    <div>
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={15}>
          <HeroSection
            setHeroSectionAppeared={setHeroSectionAppeared}
          translations={
            {
              heroTitle: prismicData?.data.contact_headline[0]?.text,
              heroDescription: prismicData?.data.contact_introduction[0]?.text,
              contactEmail: prismicData?.data.contact_cta[0]?.text,
              contactPhone: prismicData?.data.contact_secondary_cta[0]?.text,
              visitHelpDesk: prismicData?.data.helpdesk_cta[0]?.text
            }

          }/>
          <SimplestSolution shouldStartAnimation={heroSectionAppeared} showWithHero />
        </Stack>
        <Footer />
      </MWrapper>
    </div>
  )
}
