import { Locale } from '../types'
import { BASE_URL } from './env'

let baseUrl = BASE_URL

export const Locales: Locale[] = [
  {
    locale: 'en',
    prismicLocale: 'en-gb',
    menuLabel: 'English',
    path: '/en/',
    url: `${baseUrl}/en`,
  },
  {
    locale: 'fi',
    prismicLocale: 'fi',
    menuLabel: 'Suomi',
    path: '/fi/',
    url: `${baseUrl}/fi`,
  },
  {
    locale: 'sv',
    prismicLocale: 'sv-se',
    menuLabel: 'Svenska',
    path: '/sv/',
    url: `${baseUrl}/sv`,
  },
];

export const getCurrentLocale = (locale: string): Locale => {
  const localeData = Locales.find((i) => i.locale === locale)
  return localeData || Locales[0]
}
