import React, { useEffect, useState } from 'react'
import { useIsMobile } from '../common/hooks'
import { useParams } from 'react-router-dom'
import { Navigation } from '../Navigation'
import { Footer } from '../Footer'
import { HeroSection } from './components/HeroSection'
import { ContactUs } from '../Expenses/components/ContactUs'
import { SimplestSolution } from '../SimplestSolution'
import { MWrapper } from '../common/styled'
import { Stack } from '../common/components/Spacing'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { Card } from './components/Card'
import { AppIntro } from './components/AppIntro'
import { SupportLocal } from './components/SupportLocal'
import { TransparentPricing } from './components/TransparentPricing'
import { Faq } from './components/Faq'

export const Benefits = () => {
  const isMobile = useIsMobile()
  const { locale } = useParams<string>()
  const [heroSectionAppeared, setHeroSectionAppeared] = useState(false)
  const [prismicData, setPrismicData] = useState<any>(null)
  const [faqData, setFaqData] = useState<any>(null)
  const [cardSectionAppeared, setCardSectionAppeared] = useState(false)

  const [appIntroSectionAppeared, setAppIntroSectionAppeared] = useState(false)

  const showAppIntro = heroSectionAppeared && cardSectionAppeared
  const showSupportLocal = heroSectionAppeared && cardSectionAppeared && appIntroSectionAppeared


  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const pageId =
        locale === 'en'
          ? 'ZnKrsBEAAB4AyEbr'
          : locale === 'fi'
            // TODO fi and sv page ids
            ? 'ZrC0thIAACIA4PuK'
            : ''

      const data =
        await prismicClient.getByID(pageId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const pageId =
        locale === 'en'
          ? 'ZnKspBEAACAAyEiG'
          : locale === 'fi'
            // TODO fi and sv page ids
            ? 'ZrC3chIAACEA4P_n'
            : ''

      const data =
        await prismicClient.getByID(pageId, {
          lang: prismicLocale,
        })
      setFaqData(data)
    }

    getPrismicData()
  }, [locale])

  return (
    <div>
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={25}>
          <HeroSection
            setHeroSectionAppeared={setHeroSectionAppeared}
            translations={{
              heroTitle: prismicData?.data.product_page_header[0]?.text,
              heroDescription: prismicData?.data.product_page_description[0]?.text,
              heroCta: prismicData?.data.product_page_cta[0]?.text,
            }} />
          <div>
            <Card setAppeared={setCardSectionAppeared} />
            <AppIntro isReady={showAppIntro} setAppeared={setAppIntroSectionAppeared} />
            <SupportLocal isReady={showSupportLocal} />
          </div>
          <TransparentPricing translations={{
            title: prismicData?.data.pricing_header[0]?.text,
            description: prismicData?.data.pricing_description[0]?.text,
            option1: prismicData?.data.pricing_row_1[0]?.text,
            price1: prismicData?.data['pricing_row_1.2'][0]?.text,
            disclaimer: prismicData?.data.pricing_disclaimer[0]?.text,
          }} />

          <ContactUs />
          <Faq translations={{
            title: faqData?.data.faq_header[0]?.text,
            description: faqData?.data.faq_description[0]?.text,
            question1: faqData?.data.question_1[0]?.text,
            answer1: faqData?.data.answer_1[0]?.text,
            question2: faqData?.data.question_2[0]?.text,
            answer2: faqData?.data.answer_2[0]?.text,
            question3: faqData?.data.question_3[0]?.text,
            answer3: faqData?.data.answer_3[0]?.text,
            question4: faqData?.data.question_4[0]?.text,
            answer4: faqData?.data.answer_4[0]?.text,
            question5: faqData?.data.question_5[0]?.text,
            answer5: faqData?.data.answer_5[0]?.text,
          }} isMobile={!!isMobile} />

          <SimplestSolution shouldStartAnimation={heroSectionAppeared} />
        </Stack>
        <Footer />
      </MWrapper>
    </div>
  )
}