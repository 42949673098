import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from '../common/components/Modal'
import { CarouselIndicator, CarouselIndicatorNormal, SubTitle } from '../common/styled'
import tw, { styled } from 'twin.macro'
import { Stack } from '../common/components/Spacing'
import { ErrorMessage, TextInput } from '../common/components/TextInput'
import { Button } from '../common/components/Buttons'
import { ComponentVariants } from '../common/constants'
import { CLOSE_MODAL } from '../common/components/Modal/constants'
import { useAnimation } from 'framer-motion'
import { GRECAPTCHA_SITE_KEY } from '../../config/env'
import { v4 as uuidv4 } from 'uuid';
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
const Wrapper = tw.div`px-[30px]`
const ModalSubTitle = tw.div`text-[12px] uppercase`

type TitleProps = {
  locale: string
}
const Title = styled(SubTitle)<TitleProps>`
    ${tw`w-[320px] leading-[100%]`}
    ${({ locale }) => locale === 'sv' && tw`text-[40px]`}
`
const Description = tw.div`text-[14px]`
const NextButton = tw(Button)`w-full`
const StepIndicatorWrapper = tw.div`flex justify-center`
const StepIndicator = tw(CarouselIndicatorNormal)` w-[200px] cursor-default`

const StepIndicatorActive = styled(CarouselIndicator)`
  ${tw`bg-zevoyWhite w-[100px] cursor-default absolute left-[96px]`}
`

type BookDemoProps = {
  onClose: () => void
  isMobile?: boolean
}
export const GetDemo = ({ onClose, isMobile }: BookDemoProps) => {
  // GetDemo's provider is defined above the Router and hence cannot use
  // useParams in the current implementation
  const getLocale = (): string => {
    const parts = window.location.pathname.split('/').filter((part) => part !== '')
    return parts?.[0] ?? ''
  }

  const locale = useMemo(() => getLocale(), [window.location.pathname])

  const [companyName, setCompanyName] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [step, setStep] = useState(1)

  const [companyNameError, setCompanyNameError] = useState('')
  const [businessIdError, setBusinessIdError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [loadError, setLoadError] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const stepIndicatorControls = useAnimation()
  const [prismicData, setPrismicData] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const bookDemoData = await prismicClient.getSingle('book_demo', {
        lang: prismicLocale,
      })

      setPrismicData(bookDemoData)
    }

    getPrismicData()

    },[locale])

  useEffect(() => {
    const animateWidth = step === 1 ? 100 : 200
    stepIndicatorControls.start({
      width: animateWidth,
      transition: { duration: 0.2, ease: 'linear' },
    })
  }, [step, stepIndicatorControls])

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${GRECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.id = 'grecaptchaScript';

    document.head.appendChild(script);

    return () => {
      // Remove the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  const translations = {
    subtitle: prismicData?.data?.introduction?.[0]?.text,
    title1: prismicData?.data?.headline1?.[0]?.text,
    title2: prismicData?.data?.headline2?.[0]?.text,
    description1: prismicData?.data?.description1?.[0]?.text,
    description2: prismicData?.data?.description2?.[0]?.text,
    company_name: prismicData?.data?.input1?.[0]?.text,
    business_id: prismicData?.data?.input2?.[0]?.text,
    next: prismicData?.data?.cta1?.[0]?.text,
    first_name: prismicData?.data?.input3?.[0]?.text,
    last_name: prismicData?.data?.input4?.[0]?.text,
    email: prismicData?.data?.input5?.[0]?.text,
    success_title: prismicData?.data?.success_headline?.[0]?.text,
    success_subtitle: prismicData?.data?.success_text?.[0]?.text,
    close: prismicData?.data?.success_cta?.[0]?.text,
    error_company_name: prismicData?.data?.input1_error?.[0]?.text,
    error_business_id: prismicData?.data?.input2_error?.[0]?.text,
    error_first_name: prismicData?.data?.input3_error?.[0]?.text,
    error_last_name: prismicData?.data?.input4_error?.[0]?.text,
    error_email: prismicData?.data?.input5_error?.[0]?.text,
    form_error: prismicData?.data?.form_error?.[0]?.text,
  }

  const handleSubmit = async () => {
    try {
      setLoadError('')
      setSubmitting(true)
      // const gToken = await window?.grecaptcha?.execute(GRECAPTCHA_SITE_KEY);
      // const validationUrl = GRECAPTCHA_VALIDATION_URL;
      // if (!gToken || !validationUrl) {
      //   throw new Error('reCAPTCHA token or validation URL is missing');
      // }

      const fetchUrl = 'https://forms-eu1.hscollectedforms.net/collected-forms/submit/form'
      const response = await fetch(fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "contactFields":{
            "email": email,
            "firstName": firstName,
            "lastName":lastName,
            "business_id": businessId,
          },
          "formSelectorClasses":"",
          "formSelectorId":"",
          "formValues":{"submit":companyName,"phone":""},
          "labelToNameMap": {
            "submit":"company","phone":"phone"
          },
          "pageTitle":"",
          "pageUrl": window.location.href,
          "portalId": 139515614,
          "token": 1953703885,
          "type":"SCRAPED",
          "utk": uuidv4(),
          "uuid": uuidv4(),
          "version":"collected-forms-embed-js-static-1.503",
          "collectedFormClasses":"bookDemoForm"
        }),
      }).catch((error) => {
        throw new Error(`Failed to validate reCAPTCHA token: ${error}`);
      });

      if (!response.ok) {
        throw new Error(`HTTP error status: ${response.status}`);
      }

      setStep(3)
    } catch (error) {
      setLoadError(translations.form_error)
      console.error(error)
    } finally {
      setSubmitting(false)
    }
  }

  if (!prismicData) {
    return null
  }

  return (
    <Modal
      onClose={onClose}
      onBack={
        step === 2
          ? () => {
              setStep(1)
            }
          : undefined
      }
      isMobile={isMobile}
    >
      <Wrapper>
        <Stack gap={10}>
          {loadError && <ErrorMessage>{loadError}</ErrorMessage>}
          <Stack gap={5}>
            <ModalSubTitle>
              {step < 3 ? translations.subtitle : translations.success_subtitle}
            </ModalSubTitle>
            <Title locale={locale}>
              {step === 1
                ? translations.title1
                : step === 2
                  ? translations.title2
                  : translations.success_title}
            </Title>
            {(step === 1 || step === 2) && (
              <Description>
                {step === 1 ? translations.description1 : translations.description2}
              </Description>
            )}
          </Stack>
          {step === 1 && (
            <Stack gap={5}>
              <Stack gap={2.5}>
                <TextInput
                  placeholder={translations.company_name}
                  value={companyName}
                  onChange={(e) => {
                    setCompanyNameError('')
                    setCompanyName(e.target.value)
                  }}
                  errorMessage={companyNameError}
                />
                <TextInput
                  placeholder={translations.business_id}
                  value={businessId}
                  onChange={(e) => {
                    setBusinessIdError('')
                    setBusinessId(e.target.value)
                  }}
                  errorMessage={businessIdError}
                />
              </Stack>
              <NextButton
                variant={ComponentVariants.Primary}
                onClick={() => {
                  if (!companyName) {
                    setCompanyNameError(translations.error_company_name)
                  }

                  if (!businessId) {
                    setBusinessIdError(translations.error_business_id)
                  }

                  if (companyName && businessId) {
                    setStep(2)
                  }
                }}
              >
                {translations.next}
              </NextButton>
            </Stack>
          )}
          {step === 2 && (
            <Stack gap={5}>
              <Stack gap={2.5}>
                <TextInput
                  placeholder={translations.first_name}
                  value={firstName}
                  onChange={(e) => {
                    setFirstNameError('')
                    setFirstName(e.target.value)
                  }}
                  errorMessage={firstNameError}
                />
                <TextInput
                  placeholder={translations.last_name}
                  value={lastName}
                  onChange={(e) => {
                    setLastNameError('')
                    setLastName(e.target.value)
                  }}
                  errorMessage={lastNameError}
                />
                <TextInput
                  placeholder={translations.email}
                  value={email}
                  onChange={(e) => {
                    setEmailError('')
                    setEmail(e.target.value)
                  }}
                  errorMessage={emailError}
                />
              </Stack>
              <NextButton
                variant={ComponentVariants.Primary}
                onClick={() => {
                  if (!firstName) {
                    setFirstNameError(translations.error_first_name)
                  }

                  if (!lastName) {
                    setLastNameError(translations.error_last_name)
                  }

                  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
                  if (!isValidEmail) {
                    setEmailError(translations.error_email)
                  }

                  if (firstName && lastName && isValidEmail) {
                    handleSubmit()
                  }
                }}
              >
                {translations.next}
              </NextButton>
            </Stack>
          )}
          {step === 3 && (
            <Stack gap={5}>
              <NextButton
                variant={ComponentVariants.Primary}
                onClick={() => {
                  document.dispatchEvent(new CustomEvent(CLOSE_MODAL))
                }}
              >
                {translations.close}
              </NextButton>
            </Stack>
          )}
          {step < 3 && (
            <StepIndicatorWrapper>
              <StepIndicator />
              {/*<StepIndicatorActive ref={activeIndicatorRef} />*/}
              <StepIndicatorActive animate={stepIndicatorControls} initial={{ width: 0 }} />
            </StepIndicatorWrapper>
          )}
        </Stack>
      </Wrapper>
    </Modal>
  )
}
