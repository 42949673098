import React, { useEffect } from 'react'
import {
  Alignments,
  CUSTOMER_CARD_HEIGHT,
  CUSTOMER_CARD_WIDTH,
  CustomerCasesContent,
  DESKTOP_ANIMATION_SHIFT,
} from '../../common/constants'
import { Inline, Stack } from '../../common/components/Spacing'
import { Description } from '../../common/styled'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import tw, { styled } from 'twin.macro'
import { useWindowHeight } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'
import { useParams } from 'react-router-dom'

const Wrapper = tw(motion.div)``
const Inner = tw.div``

const MobileWrapper = styled(motion.div)`
  ${tw`w-[300px] mx-auto`}
`
const MobileDescription = tw(Description)`text-[14px] leading-[1.7]`

type LeftWrapperProps = {
  imgUrl: string
}
const LeftWrapper = styled.div<LeftWrapperProps>`
  ${tw`rounded-[20px]`}
  width: ${CUSTOMER_CARD_WIDTH}px;
  height: ${CUSTOMER_CARD_HEIGHT}px;
  background: url(${({ imgUrl }: LeftWrapperProps) => imgUrl});
  background-repeat: no-repeat;
  background-size: ${({ imgUrl }: LeftWrapperProps) => imgUrl === CustomerCasesContent.fingersoft.cardImage ? 'contain' : 'cover'};
  background-position: center;
`
const RightWrapper = tw.div`w-[290px]`
const RightWrapperDescription = tw(Description)`text-[14px] text-left leading-[1.6]`

const DlaPiperLogo = styled.div`
    ${tw`text-center text-[32px] mb-[40px]`}
    font-family: 'Slussen-Medium', sans-serif;
    font-weight: 500;
`

type DisclaimerSectionProps = {
  description?: {text: string}[];
  logo: React.ReactNode;
  imgUrl: string;
  hasHeroSectionAppeared?: boolean;
  setHasDisclaimerAppeared?: (hasAppeared: boolean) => void;
}
export const DisclaimerSection = ({description, logo, imgUrl, hasHeroSectionAppeared, setHasDisclaimerAppeared}: DisclaimerSectionProps) => {
  const isMobile = useIsMobile();
  const { customer } = useParams()

  /* Mobile */
  const windowHeight = useWindowHeight()
  const appearanceRatio = `-${0.7 * windowHeight}px`
  const [mobileRef, mobileInView] = useInView({
    threshold: 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const mobileControls = useAnimation()

  /* Desktop */
  const wrapperControls = useAnimation()

  useEffect(() => {
    const desktopSequence = async () => {
      await wrapperControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.6 } })
    }
    if (!isMobile) {
      desktopSequence()
    }
  }, [isMobile, wrapperControls])

  useEffect(() => {
    const mobileSequence = async () => {
      await mobileControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      setHasDisclaimerAppeared?.(true)
    }

    if (isMobile && mobileInView && hasHeroSectionAppeared) {
      mobileSequence()
    }
  }, [isMobile, mobileControls, mobileInView, hasHeroSectionAppeared])

  if (isMobile) {
    return (
      <MobileWrapper
        animate={mobileControls}
        initial={{ opacity: 0 }}
        ref={mobileRef}>
        <Stack gap={7.5} align={Alignments.Center} width="300px">
          {customer === 'dla_piper' ? <DlaPiperLogo>DLA Piper</DlaPiperLogo>  : logo}
          <MobileDescription>
            <Stack>
              {
                description?.map((text, index) => (
                  <div key={index}>{text.text}</div>
                ))
              }
            </Stack>
          </MobileDescription>
        </Stack>
      </MobileWrapper>
    )
  }
  return (
    <Wrapper animate={wrapperControls} initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
      <Inner>
        <Inline verticalalign={Alignments.Center} align={Alignments.Center} gap={15}>
          <LeftWrapper imgUrl={imgUrl} />
          <RightWrapper>
              {customer === 'dla_piper' ? <DlaPiperLogo>DLA Piper</DlaPiperLogo>  : logo}
              <RightWrapperDescription>
                <Stack>
                  {
                    description?.map((text, index) => (
                      <div key={index}>{text.text}</div>
                    ))
                  }
                </Stack>
              </RightWrapperDescription>
          </RightWrapper>
        </Inline>
      </Inner>
    </Wrapper>
  )
}