import React, { useEffect, useState } from 'react'
import { useIsMobile } from '../../common/hooks'
import { useWindowHeight } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { DESKTOP_ANIMATION_SHIFT } from '../../common/constants'
import { Stack } from '../../common/components/Spacing'
import { Description, SubTitle } from '../../common/styled'
import tw, { styled } from 'twin.macro'
import {
  Question, TableDescription,
  TableItem,
  TableItemContent,
  TableItemDescription,
  TableItemWrapper,
} from '../../Expenses/components/ThingsToKnow'
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg'

type WrapperProps = {
  isMobile: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw`w-[900px] mx-auto`}
  ${({ isMobile }) => isMobile && tw`w-[100%]`}
`

type ThingsToKnowProps = {
  isMobile: boolean
  translations: {
    title: string
    description: string
    question1: string
    answer1: string
    question2: string
    answer2: string
    question3: string
    answer3: string
    question4: string
    answer4: string
    question5: string
    answer5: string
  }

}

export const Faq = ({ translations, isMobile }: ThingsToKnowProps) => {
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const [thingsToKnowRef, thingsToKnowInView] = useInView({
    threshold: (windowHeight > 900) ? 0.3 : 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const controls = useAnimation()

  useEffect(() => {
    if (thingsToKnowInView) {
      if (isMobile) {
        controls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
        return
      }

      controls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [isMobile, thingsToKnowInView])

  const [isQuestion1Open, setIsQuestion1Open] = useState(false)
  const [isQuestion2Open, setIsQuestion2Open] = useState(false)
  const [isQuestion3Open, setIsQuestion3Open] = useState(false)
  const [isQuestion4Open, setIsQuestion4Open] = useState(false)
  const [isQuestion5Open, setIsQuestion5Open] = useState(false)


  const closeAll = () => {
    setIsQuestion1Open(false)
    setIsQuestion2Open(false)
    setIsQuestion3Open(false)
    setIsQuestion4Open(false)
    setIsQuestion5Open(false)
  }

  return (
    <Wrapper
      animate={controls}
      ref={thingsToKnowRef}
      isMobile={!!isMobile}
      initial={isMobile ? { opacity: 0 } : { opacity: 0, y: -DESKTOP_ANIMATION_SHIFT }}
    >
      <Stack gap={7.5}>
        <SubTitle isMobile={isMobile}>{translations.title}</SubTitle>
        <TableDescription isMobile={isMobile}>{translations.description}</TableDescription>
        <Stack gap={5}>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion1Open(!isQuestion1Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question1}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion1Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer1}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion2Open(!isQuestion2Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question2}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion2Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer2}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion3Open(!isQuestion3Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question3}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion3Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer3}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion4Open(!isQuestion4Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question4}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion4Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer4}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion5Open(!isQuestion5Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question5}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion5Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer5}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
        </Stack>
      </Stack>
    </Wrapper>
  )
}