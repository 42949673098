import React from 'react'
import { theme } from 'twin.macro'
import Marquee from 'react-fast-marquee'
import { ReactComponent as Netvisor } from '../../../assets/icons/integrations/netvisor.svg'
import { ReactComponent as Fortnox } from '../../../assets/icons/integrations/fortnox.svg'
import { ReactComponent as MeritActiva } from '../../../assets/icons/integrations/merit_activa.svg'
import { ReactComponent as Directo } from '../../../assets/icons/integrations/directo.svg'
import { ReactComponent as Procountor } from '../../../assets/icons/integrations/procountor.svg'
import { ReactComponent as Fivaldi } from '../../../assets/icons/integrations/fivaldi.svg'
import { ReactComponent as M2 } from '../../../assets/icons/integrations/m2.svg'
import { ReactComponent as Netsuite } from '../../../assets/icons/integrations/netsuite.svg'
import { ReactComponent as SomeIntegration } from '../../../assets/icons/integrations/integration.svg'
import { ReactComponent as MSDynamics } from '../../../assets/icons/integrations/ms_dynamics.svg'
import { ReactComponent as Briox } from '../../../assets/icons/integrations/briox.svg'
import { ReactComponent as Fennoa } from '../../../assets/icons/integrations/fennoa.svg'
import { ReactComponent as Heeros } from '../../../assets/icons/integrations/heeros.svg'
import { ReactComponent as Lemonsoft } from '../../../assets/icons/integrations/lemonsoft.svg'
import { ReactComponent as Talenom } from '../../../assets/icons/integrations/talenom.svg'
import { ReactComponent as Sie } from '../../../assets/icons/integrations/sie.svg'
import { ReactComponent as Cwt } from '../../../assets/icons/integrations/cwt.svg'

export const Integrations = () => {
  return (
    <Marquee
      loop={0}
      speed={25}
      gradient
      gradientWidth={114}
      gradientColor={theme('colors.zevoyBlueBlack')}
    >
      <Netvisor />
      <Fortnox />
      <MeritActiva />
      <Directo />
      <Procountor />
      <Fivaldi />
      <M2 />
      <MSDynamics />
      <Lemonsoft />
      <Heeros />
      <Fennoa />
      <Briox />
      <Cwt />
      <SomeIntegration />
      <Talenom />
      <Sie />
      <Netsuite />
    </Marquee>
  )
}
