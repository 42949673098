import React, { FunctionComponent, SVGProps, useEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Stack } from '../common/components/Spacing'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  CarouselIndicatorActive,
  CarouselIndicatorNormal,
  TextBig,
  TextHighlight,
} from '../common/styled'
import { Alignments, CustomerCasesContent } from '../common/constants'
import { useIsMobile } from '../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useWindowHeight } from '@react-hook/window-size'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { useParams } from 'react-router-dom'

type WrapperProps = {
  $isMobile?: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
    ${tw`text-center`}
    ${({ $isMobile }: WrapperProps) => $isMobile && tw`mb-[60px]`}
`
const Title = tw.div`text-sm`

type TestimonialCarouselProps = {
  height?: number
}
const TestimonialCarousel = styled(Carousel)<TestimonialCarouselProps>`
  height: ${({ height }: TestimonialCarouselProps) => height ? `${height}px` : '530px'};
  & .carousel-slider {
    height: 100%;
  }
`
const TestimonialWrapper = tw(motion.div)``

type TestimonialProps = {
  isMobile?: boolean
}
const Testimonial = styled(TextBig)<TestimonialProps>`
  ${tw`text-4xl w-[820px] leading-[103%] text-[48px]`}
  ${({ isMobile }: TestimonialProps) => isMobile && tw`text-[32px] w-[350px]`}
`
const TestimonialAuthor = tw.div`text-sm w-[290px]`

const CarouselIndicatorWrapper = tw.li`inline mr-3 last:mr-0 relative`
const LogoWrapper = tw.div`w-[130px]`

type TestimonialsProps = {
  setTestimonialsAppeared?: (appeared: boolean) => void
  predefinedTestimonials?: any[]
  height?: number
}
export const Testimonials = ({setTestimonialsAppeared, predefinedTestimonials, height}: TestimonialsProps) => {
  const { locale, customer } = useParams()
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0)
  const activeIndicatorRef = useRef(null)
  const isMobile = useIsMobile()
  const activeIndicatorControls = useAnimation()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`

  const testimonialsControls = useAnimation()
  const [testimonialsRef, testimonialsInView] = useInView({
    threshold: (windowHeight > 900) ? 0.3 : 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [isShown, setIsShown] = useState<boolean>(false)
  const [testimonials, setTestimonials] = useState<any>(predefinedTestimonials ?? [])

  useEffect(() => {
    setTestimonials(predefinedTestimonials)
    if (predefinedTestimonials?.length) {
      setTestimonialsAppeared?.(true)
    }
  }, [predefinedTestimonials])

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const testimonialsData = await prismicClient.getAllByType('quote', {
        lang: prismicLocale,
      })

      const testimonialsWithLogos = testimonialsData
        .filter((testimonial) => {
          return !!customer
            ? testimonial.data?.company_code?.[0]?.text === customer
            : Object.keys(CustomerCasesContent).some((key) => key === testimonial.data?.company_code?.[0]?.text)
        })
        .reduce((acc: {
          quoteStart: string,
          quoteColor: string,
          quoteEnd: string,
          quotedPerson: string,
          logo: FunctionComponent<SVGProps<SVGSVGElement>>
        }[], testimonial: any) => {
          const quoteStart = testimonial.data?.quote_start?.[0]?.text ?? ''
          const quoteColor = testimonial.data?.quote_color?.[0]?.text ?? ''
          const quoteEnd = testimonial.data?.quote_end?.[0]?.text ?? ''
          const quotedPerson = testimonial.data?.quoted_person?.[0]?.text
          const logo = CustomerCasesContent[testimonial.data?.company_code?.[0]?.text].logo

          return [
            ...acc,
            {
              quoteStart,
              quoteColor,
              quoteEnd,
              quotedPerson,
              logo,
            }
            ]
        }, [])

      await setTestimonials(testimonialsWithLogos)
      setTestimonialsAppeared?.(true)
    }
    if (!customer) {
      getPrismicData()
    }
  }, [locale])

  useEffect(() => {
    if (!!testimonials?.length && testimonialsInView) {
      testimonialsControls.start({
        opacity: 1,
        transition: { duration: 0.3, delay: 0.3 },
      })
    }
  }, [testimonialsInView, testimonialsControls, testimonials])

  useEffect(() => {
    if (testimonialsInView && !isShown) {
      setIsShown(true)
    }
  }, [testimonialsInView])

  useEffect(() => {
    if (!testimonials?.length || !isShown) {
      return
    }

    activeIndicatorControls.start({
      width: '70px',
      transition: { duration: 9, ease: 'linear' },
    })

  }, [activeIndicatorControls, selectedItemIndex, testimonials, isShown])

  const handleIndicatorClick = (
    e: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLSpanElement>,
    _: number,
    onClickHandler: (e: React.MouseEvent | React.KeyboardEvent) => void,
  ) => {
    onClickHandler(e)
  }

  if (!testimonials?.length) {
    return null
  }

  return (
    <Wrapper ref={testimonialsRef} animate={testimonialsControls} initial={{ opacity: 0 }}>
      <Stack gap={10}>
        <Title>{testimonials?.[0]?.data?.quote_description?.[0]?.text}</Title>
        <TestimonialCarousel
          height={height}
          transitionTime={0}
          showThumbs={false}
          showArrows={false}
          showStatus={false}
          autoPlay
          infiniteLoop={true}
          interval={10000}
          stopOnHover={false}
          onChange={(index) => {
            setSelectedItemIndex(index)
          }}
          renderIndicator={(onClickHandler, isSelected, index, label) => (
            !customer &&
            <CarouselIndicatorWrapper>
              <CarouselIndicatorNormal
                onClick={(e) => {
                  e.preventDefault()
                  // user is forbidden  to click on indicators. Activate this code if it needs to be allowed
                  // handleIndicatorClick(e, index, onClickHandler)
                }}
                onKeyDown={onClickHandler}
                key={index}
                role="button"
                tabIndex={0}
                aria-label={`${label} ${index + 1}`}
                $isSelected={isSelected}
                $isWhite={index < selectedItemIndex}
              />
              {isSelected && (
                <CarouselIndicatorActive
                  ref={activeIndicatorRef}
                  initial={{ width: '10px' }}
                  animate={activeIndicatorControls}
                />
              )}
            </CarouselIndicatorWrapper>
          )}
        >
          {testimonials.map((testimonial: any, index: number) => {
            return (
              <TestimonialWrapper
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: selectedItemIndex === index ? 1 : 0 }}
                transition={
                  selectedItemIndex === index ? { duration: 1.5 } : { duration: 1.5, delay: 1.5 }
                }
              >
                <Stack gap={10} align={Alignments.Center}>
                  <Testimonial isMobile={isMobile}>
                    {testimonial.quoteStart ? `${testimonial.quoteStart} ` : ``}
                    <TextHighlight>{testimonial.quoteColor}</TextHighlight>
                    {testimonial.quoteEnd ? ` ${testimonial.quoteEnd}` : ``}
                  </Testimonial>
                  <Stack gap={5} align={Alignments.Center}>
                    {testimonial.logo && (
                      <LogoWrapper>
                        <testimonial.logo />
                      </LogoWrapper>
                    )}
                    <TestimonialAuthor>{testimonial.quotedPerson}</TestimonialAuthor>
                  </Stack>
                </Stack>
              </TestimonialWrapper>
            )
          })}
        </TestimonialCarousel>
      </Stack>
    </Wrapper>
  )
}
