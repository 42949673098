import React, { useEffect } from 'react'
import tw, { css, styled } from 'twin.macro'
import { Stack } from '../../common/components/Spacing'
import { Button } from '../../common/components/Buttons'
import { NavLink, useParams } from 'react-router-dom'
import { Alignments, ComponentVariants, Sizes } from '../../common/constants'
import { TextBig } from '../../common/styled'
import { useIsMobile } from '../../common/hooks'
import { AnimationControls, motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

type WrapperProps = {
  height: string
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw`flex flex-col text-left w-[585px] justify-start pt-[20px] px-[61px] pb-[59px] box-border`}
  border-radius: 20px;
  background: linear-gradient(154deg, #172135 4.41%, rgba(23, 33, 53, 0) 63.27%);
  flex-basis: calc(50% - 15px);
  ${({ height }: WrapperProps) =>
    height &&
    css`
      height: ${height};
    `}
`
const Title = tw(TextBig)`text-[40px]`
const Price = tw.div`text-[16px]`

type DescriptionProps = {
  descriptionHeight?: string
}
const Description = styled.div<DescriptionProps>`
    ${tw`text-sm leading-normal`}
    height: auto;
    ${({ descriptionHeight }: DescriptionProps) =>
      descriptionHeight &&
      css`
        min-height: ${descriptionHeight};
      `}
`
const Content = tw.div`w-[450px]`

type MobileWrapperProps = {
  paddingTop?: string
  height?: string
}
const MobileWrapper = styled(Wrapper)<MobileWrapperProps>`
  ${tw`flex flex-col text-left  basis-[450px] justify-end px-0 pb-[40px] box-border w-[350px]`}
  ${({ paddingTop }: MobileWrapperProps) =>
    paddingTop &&
    css`
      padding-top: ${paddingTop};
    `}

`
const MobileTitle = tw(TextBig)`text-[24px]`
const MobileContent = tw.div`w-[290px]`

type ImageProps = {
  $marginTop?: string
  $marginLeft?: string
  $isMobile?: boolean
}
const Image = styled.img<ImageProps>`
  ${tw`text-center inline-block`}
  height: 310px;
 
  ${({ $marginTop }: ImageProps) =>
  $marginTop &&
  css`
    top: ${$marginTop};
  `}
  ${({ $marginLeft }: ImageProps) =>
  $marginLeft &&
  css`
    left: ${$marginLeft};
  `}
  ${({ $isMobile }: ImageProps) =>
  $isMobile &&
  css`
      height: 210px;
  `}
`
const ImageWrapper = tw.div``
const ContentWrapper = tw.div``
const FindOutMore = tw(NavLink)`w-[290px]`

type SimplestSolutionTileProps = {
  title: string
  subTitle: string
  description: string
  image: string
  ctaText: string
  ctaLink: string
  index: number
  imageMarginTop?: string
  imageMarginLeft?: string
  descriptionHeight?: string
  controls?: AnimationControls
  threshold?: number
  delay?: number
  shouldStartAnimation?: boolean
}
export const SimplestSolutionTile = ({
  title,
  subTitle,
  description,
  image,
  ctaText,
  ctaLink,
  index,
  imageMarginTop,
  imageMarginLeft,
  descriptionHeight,
  controls,
  threshold = 0.2,
  delay = 0.3,
  shouldStartAnimation = true,
}: SimplestSolutionTileProps) => {
  const isMobile = useIsMobile()
  const mobileControls = useAnimation()
  const [tileRef, tileInView] = useInView({
    threshold,
  })

  useEffect(() => {
    if (tileInView) {
      if (isMobile && shouldStartAnimation) {
        mobileControls.start({
          opacity: 1,
          transition: { duration: 0.3, delay },
          y: 0,
        })
      }
    }
  }, [tileInView, mobileControls])

  if (isMobile) {
    return (
      <MobileWrapper
        ref={tileRef}
        animate={mobileControls}
        initial={{ opacity: 0, y: 70 }}
        paddingTop={imageMarginTop}
      >
        <Stack gap={7.5} align={Alignments.Center}>
          <ContentWrapper>
            <Image src={image} alt={title} $isMobile  />
            <MobileContent>
              <Stack gap={5} align={Alignments.Left}>
                <Stack gap={4}>
                  <MobileTitle>{title}</MobileTitle>
                  <Price>{subTitle}</Price>
                </Stack>
                <Description>{description}</Description>
              </Stack>
            </MobileContent>
          </ContentWrapper>
          <FindOutMore to={ctaLink}>
            <Button width="100%" variant={ComponentVariants.Primary} size={Sizes.Medium}>
              {ctaText}
            </Button>
          </FindOutMore>
        </Stack>
      </MobileWrapper>
    )
  }
  return (
    <Wrapper
      ref={tileRef}
      animate={controls}
      initial={{ opacity: 0, y: 100 }}
      height={'645px'}>
      <Stack gap={7.5} align={Alignments.Center}>
        <div>
          <ImageWrapper>
            <Image
              src={image}
              alt={title}
              $marginTop={imageMarginTop}
              $marginLeft={imageMarginLeft}
            />
          </ImageWrapper>
          <Content>
            <Stack gap={5} align={Alignments.Left}>
              <Title>{title}</Title>
              <Stack gap={2.5}>
                <Price>{subTitle}</Price>
                <Description descriptionHeight={descriptionHeight}>{description}</Description>
              </Stack>
            </Stack>
          </Content>
        </div>
        <NavLink to={ctaLink}>
          <Button variant={ComponentVariants.Primary} size={Sizes.Small}>
            {ctaText}
          </Button>
        </NavLink>
      </Stack>
    </Wrapper>
  )
}
