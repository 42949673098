import React, { useEffect, useState } from 'react'
import emailjs from '@emailjs/browser';
import { useIsMobile } from '../../common/hooks'
import { useWindowHeight } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { Alignments, ComponentVariants, DESKTOP_ANIMATION_SHIFT, Sizes } from '../../common/constants'
import { Stack } from '../../common/components/Spacing'
import { Description, SubTitle } from '../../common/styled'
import tw, { styled } from 'twin.macro'
import { Button } from '../../common/components/Buttons'
import { TextInput } from '../../common/components/TextInput'
import { EMAILJS_PUBLIC_KEY } from '../../../config/env';

type WrapperProps = {
  isMobile: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw`w-[900px] mx-auto`}
  ${({ isMobile }) => isMobile && tw`w-[100%]`}
`

type JoinTheNetworkTitleProps = {
  isMobile: boolean
}
const JoinTheNetworkTitle = styled(SubTitle)<JoinTheNetworkTitleProps>`
  ${tw`leading-[40px]`}
  ${({ isMobile }) => isMobile && tw`leading-[28px]`}
`
const JoinTheNetworkDescription = tw(Description)`text-sm leading-[12px]`
const CtaButton = tw(Button)`w-full`

type FormWrapperProps = {
  isMobile: boolean
}
const FormWrapper = styled.div<FormWrapperProps>`
  ${tw`w-[585px]`}
  ${({ isMobile }) => isMobile && tw`w-full`}
`

type TermsDisclaimerProps = {
  isMobile: boolean
}
const TermsDisclaimer = styled(Description)<TermsDisclaimerProps>`
  ${tw`text-[11px]`}
  ${({ isMobile }) => isMobile && tw`-mt-[6px] mb-[40px]`}
`

const TermsLink = tw.a`underline`

type ThanksTitleProps = {
  isMobile: boolean
}
const ThanksTitle = styled(SubTitle)<ThanksTitleProps>`
  ${({ isMobile }) => isMobile && tw`leading-[48px] text-[48px] max-w-[330px] mb-[20px]`}
`

type JoinTheNetworkProps = {
  translations: {
    title: string
    description: string
    cta: string
    thankYouTitle: string
    thankYouDescription: string
    disclaimer: string
    disclaimer_link: string
    company_name: string
    business_id: string
    email: string
    phone: string
    amount_of_restaurants: string
  }
}
export const JoinTheNetwork = ({translations}: JoinTheNetworkProps) => {
  const isMobile = useIsMobile()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const [joinTheNetworkRef, joinTheNetworkInView] = useInView({
    threshold: 0.2,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const controls = useAnimation()
  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [businessId, setBusinessId] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [restaurantCount, setRestaurantCount] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)

  useEffect(() => {
    if (joinTheNetworkInView) {
      if (isMobile) {
        controls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
        return
      }

      controls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [isMobile, joinTheNetworkInView, controls])

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    const isRestaurantCountValid = parseInt(restaurantCount) > 0
    const areAllFieldsFilled = !!companyName && !!businessId && !!email && !!phone && !!restaurantCount

    setIsFormValid(isEmailValid && isRestaurantCountValid && areAllFieldsFilled)
  }, [companyName, businessId, email, phone, restaurantCount])


  const handleSubmit = async () => {
    if (isFormValid) {
      const templateParams = {
        company_name: companyName,
        business_id: businessId,
        email: email,
        phone: phone,
        restaurant_count: restaurantCount,
      };

      try {
        setIsLoading(true)
        await emailjs.send(
          'service_mcp9rq6', 
          'template_47llt9s', 
          templateParams,
          EMAILJS_PUBLIC_KEY
        );
        setStep(2);
      } catch (error) {
        console.error('FAILED...', error);
      } finally {
        setIsLoading(false)
      }
    }
  };

  const fadeVariants = {
    hidden: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0 },
  }

  return (
    <Wrapper
      animate={controls}
      isMobile={!!isMobile}
      ref={joinTheNetworkRef}
      initial={isMobile ? { opacity: 0 } : { opacity: 0, y: -DESKTOP_ANIMATION_SHIFT }}
    >
        <AnimatePresence mode="wait">
          {step === 1 ? (
            <motion.div
              key="step1"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={fadeVariants}
              transition={{ duration: 0.2 }}
            >
              <Stack gap={7.5} align={!!isMobile ? Alignments.Start : Alignments.Center}>
                <JoinTheNetworkTitle isMobile={!!isMobile}>{translations.title}</JoinTheNetworkTitle>
                <JoinTheNetworkDescription isMobile={!!isMobile}>{translations.description}</JoinTheNetworkDescription>
                <FormWrapper isMobile={!!isMobile}>
                  <Stack gap={5} align={Alignments.Start}>
                    <Stack gap={2.5}>
                      <TextInput
                        placeholder={translations.company_name}
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <TextInput
                        placeholder={translations.business_id}
                        value={businessId}
                        onChange={(e) => setBusinessId(e.target.value)}
                      />
                      <TextInput
                        placeholder={translations.email}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <TextInput
                        placeholder={translations.phone}
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <TextInput
                        placeholder={translations.amount_of_restaurants}
                        type="number"
                        value={restaurantCount}
                        onChange={(e) => setRestaurantCount(e.target.value)}
                      />
                    </Stack>
                    <CtaButton
                      variant={ComponentVariants.Primary}
                      size={Sizes.Medium}
                      onClick={handleSubmit}
                      disabled={!isFormValid || isLoading}
                    >
                      {translations.cta}
                    </CtaButton>
                    <TermsDisclaimer isMobile={!!isMobile}>
                      <span>{`${translations.disclaimer} `}</span>
                      <TermsLink href="/docs/Zevoy-General-Terms-and-Conditions-20230301.pdf" target="_blank">
                        {translations.disclaimer_link}
                      </TermsLink>.
                    </TermsDisclaimer>
                  </Stack>
                </FormWrapper>
              </Stack>
            </motion.div>
          ) : (
            <motion.div
              key="step2"
              initial="hidden"
              animate="visible"
              variants={fadeVariants}
              transition={{ duration: 0.5 }}
            >
              <Stack gap={5} align={!!isMobile ? Alignments.Start : Alignments.Center}>
                <JoinTheNetworkDescription isMobile={isMobile}>{translations.thankYouDescription}</JoinTheNetworkDescription>
                <ThanksTitle isMobile={!!isMobile}>{translations.thankYouTitle}</ThanksTitle>
              </Stack>
            </motion.div>
          )}
      </AnimatePresence>
    </Wrapper>
  )
}
