import React, { useEffect, useMemo, useState } from 'react'
import { Navigation } from '../Navigation'
import { Footer } from '../Footer'
import { SimplestSolution } from '../SimplestSolution'
import { Stack } from '../common/components/Spacing'
import { MWrapper } from '../common/styled'
import { useIsMobile } from '../common/hooks'
import { Testimonials } from '../Testimonials'
import { HeroSection } from './components/HeroSection'
import { DisclaimerSection } from './components/DisclaimerSection'
import tw, { styled } from 'twin.macro'
import { Numbers } from './components/Numbers'
import { Content } from './components/Content'
import { MobileImage } from './components/MobileImage'
import { OtherCustomerCases } from './components/OtherCustomerCases'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { useParams } from 'react-router-dom'
import { NotFoundError } from '@prismicio/client'
import { CustomerCasesContent, OtherCustomerCasesIds } from '../common/constants'
import { LocaleIndex } from '../common/types'

const CustomerCaseInner = () => {
  const { locale, customer } = useParams<string>()

  const isMobile = useIsMobile()
  const [hasHeroSectionAppeared, setHasHeroSectionAppeared] = useState(false)
  const [hasDisclaimerAppeared, setHasDisclaimerAppeared] = useState(false)
  // const [haveNumbersAppeared, setHaveNumbersAppeared] = useState(false)
  const [haveTestimonialsAppeared, setHaveTestimonialsAppeared] = useState(false)
  const [prismicCustomerData, setPrismicCustomerData] = useState<any>(null)
  const [otherCustomerCases, setOtherCustomerCases] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      try {
        const localeData = getCurrentLocale(locale ?? '');
        const prismicLocale = localeData.prismicLocale;

        if (!customer) {
          return
        }
        const prismicId = CustomerCasesContent[customer]?.prismicId?.[locale as LocaleIndex] ?? ''
        const customerData = await prismicClient.getByID(prismicId, {
          lang: prismicLocale,
        });
        setPrismicCustomerData(customerData);

        const customerCasesData = await prismicClient.getAllByType('customer_case', {
          lang: prismicLocale,
        })

        const filteredCustomerCases =
          customerCasesData.filter((caseData: any) => caseData.id !== prismicId && OtherCustomerCasesIds.includes(caseData.id))

        const finalCustomerCases = filteredCustomerCases.length > 3
          ? filteredCustomerCases.slice(0, 3)
          : filteredCustomerCases
        setOtherCustomerCases(finalCustomerCases)

      } catch (error) {
        if (error instanceof NotFoundError) {
          console.log("No documents found.");
          return null;
        }
        console.error("An error occurred:", error);
        return null;
      }
    }
    getPrismicData()
    setHasHeroSectionAppeared(false)
    setHasDisclaimerAppeared(false)
  }, [locale, customer])

  const heroTranslations  = useMemo(() => ({
    heroTopTitle: prismicCustomerData?.data?.zevoy_customer_case_text?.[0]?.text ?? '',
    heroTitle: prismicCustomerData?.data?.case_headline?.[0].text ?? '',
  }), [prismicCustomerData])

  const caseTranslation = useMemo(() => ({
    titles: {
      company: prismicCustomerData?.data?.the_company1?.[0].text ?? '',
      challenge: prismicCustomerData?.data?.the_challenge?.[0].text ?? '',
      solution: prismicCustomerData?.data?.the_solution?.[0].text ?? '',
      result: prismicCustomerData?.data?.the_results?.[0].text ?? '',
    },
    company: prismicCustomerData?.data?.case_description,
    challenge: prismicCustomerData?.data?.the_challenge_description,
    solution: prismicCustomerData?.data?.the_solution_description,
    result: prismicCustomerData?.data?.the_results_description,
  }), [prismicCustomerData])

  const quoteTranslation = useMemo(() => ({
    quoteStart: prismicCustomerData?.data?.customer_case_quote?.[0].text ?? '',
    quotedPerson: prismicCustomerData?.data?.customer_case_quote_name?.[0].text ?? '',
    logo: customer === 'dla_piper' ? null : CustomerCasesContent[customer!]?.logo ?? null
  }), [prismicCustomerData, customer])

  const customerKey = Object.keys(CustomerCasesContent).find((key) =>
    CustomerCasesContent?.[key]?.id === customer
  ) ?? ''

  const customerContent = CustomerCasesContent?.[customerKey]

  const CustomerLogo = customerContent?.logo
    ? styled(customerContent.logo)`${tw`max-w-[160px] max-h-[100px] text-center mx-auto mb-[40px]`}}`
    : null
  const CustomerLogoMobile = CustomerLogo
    ? styled(CustomerLogo)` ${tw`mt-0`}}`
    : null

  const logo = isMobile
    ? CustomerLogoMobile
      ? <CustomerLogoMobile />
      : null
    : CustomerLogo
      ? <CustomerLogo />
      : null

  return (
    <div>
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={22.5}>
          {isMobile && <MobileImage imgUrl={customerContent?.cardImage}  />}
          <HeroSection setHeroSectionAppeared={setHasHeroSectionAppeared} translations={heroTranslations} />
          <DisclaimerSection
            hasHeroSectionAppeared={hasHeroSectionAppeared}
            setHasDisclaimerAppeared={setHasDisclaimerAppeared}
            imgUrl={customerContent?.cardImage}
            logo={logo}
            description={prismicCustomerData?.data?.the_company_description ?? []} />

          {/* Numbers are hidden until the consent is given */}
          {/*<Numbers*/}
          {/*  setHaveNumbersAppeared={setHaveNumbersAppeared}*/}
          {/*  shouldStartAnimation={hasHeroSectionAppeared && hasDisclaimerAppeared}*/}
          {/*  translations={{*/}
          {/*    amount_of_subscriptions: prismicCustomerData?.data?.amount_of_subscriptions?.[0].text ?? '',*/}
          {/*    amount_of_cards: prismicCustomerData?.data?.amount_of_cards?.[0].text ?? '',*/}
          {/*    amount_of_users: prismicCustomerData?.data?.amount_of_users?.[0].text ?? '',*/}

          {/*  }}*/}
          {/*  subscriptions={prismicCustomerData?.data?.subscriptions_number ?? 0}*/}
          {/*  cards={prismicCustomerData?.data?.cards_number ?? 0}*/}
          {/*  users={prismicCustomerData?.data?.users_number ?? 0} />*/}
          <Content
            caseTranslation={caseTranslation}
            hasHeroSectionAppeared={isMobile ? (hasHeroSectionAppeared && hasDisclaimerAppeared) : hasHeroSectionAppeared}
          />
          {
            (isMobile === false || (hasHeroSectionAppeared && hasDisclaimerAppeared/*  && haveNumbersAppeared */)) && (
              <Testimonials
                height={
                  (customer === 'dla_piper' && locale !== 'sv' ) ||
                  (customer === 'hansa_medical' && !isMobile ) ||
                  customer === 'korkia' ||
                  customer === 'upright_project' ||
                  customer === 'oixio' ||
                  customer === 'showell' ||
                  customer === 'vainu'
                    ? 330 : undefined}
                setTestimonialsAppeared={setHaveTestimonialsAppeared}
                predefinedTestimonials={[quoteTranslation]} />
            )
          }

          {
            (isMobile === false || (haveTestimonialsAppeared && !!prismicCustomerData)) &&
            (
                  <OtherCustomerCases
                    havePrerequisitesAppeared={hasHeroSectionAppeared && (isMobile ? haveTestimonialsAppeared : true)}
                    otherCustomerCases={otherCustomerCases}
                    translations={{
                      viewAllCases: prismicCustomerData?.data?.view_all_cases?.[0].text ?? '',
                    }}
                  />
              )
          }
          <SimplestSolution shouldStartAnimation={hasHeroSectionAppeared} />
          <Footer />
        </Stack>
      </MWrapper>
    </div>
  )
}

export const CustomerCase = () => {
  const { locale, customer } = useParams()

  return <CustomerCaseInner key={`${customer}_${locale}`} />
}
