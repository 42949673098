import React, { useEffect, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Inline, Stack } from '../../common/components/Spacing'
import { Description, SubTitle } from '../../common/styled'
import { Alignments, DESKTOP_ANIMATION_SHIFT } from '../../common/constants'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import {
  useCalculateVisibleHeight,
  useDynamicScrollTransform,
  useDynamicYOffsetMax,
  useIsMobile,
  useVerticalScrollWithThrottle,
} from '../../common/hooks'
import { useWindowHeight } from '@react-hook/window-size'
import { isElementBottomInView } from '../../common/utils'
import { getCurrentLocale } from '../../../config/locales'
import { prismicClient } from '../../../config/prismicClient'
import { useParams } from 'react-router-dom'

type AppImageProps = {}
const AppImage = styled.img<AppImageProps>`
  ${tw`w-[300px]`}
`

const MobileWrapper = tw.div``
const MobileCards = tw(motion.img)`w-[100%] mb-[50px] mt-[100px]`

const MobileSubTitle = tw(SubTitle)`text-[32px] leading-[110.5%]`
const MobileDescription = tw(Description)`text-[14px]`

// Mobile
const VIEWPORT_PADDING = 40
const IMAGE_SHIFT_START = 0.3
const IMAGE_SHIFT_END = 1
const MAX_SHIFT_COEFFICIENT = 0.25

const IMAGE_SCALE_START = 1.3
const IMAGE_SCALE_END = 1

// Desktop
const DESKTOP_IMAGE_SCALE_START = 2.5
const DESKTOP_IMAGE_SCALE_END = 1
const DESKTOP_IMAGE_HORIZONTAL_SHIFT_START = 390
const DESKTOP_IMAGE_HORIZONTAL_SHIFT_END = 0

const Wrapper = styled(motion.div)`
  ${tw`mx-[62px] mt-[550px]`}
  padding-left: ${DESKTOP_IMAGE_HORIZONTAL_SHIFT_START}px;
`

const RightWrapper = styled(motion.div)`
  width: 520px;
`

const LeftWrapper = styled(motion.div)`
  z-index: 1;
`

type VirtualCardProps = {
  setAppeared?: (appeared: boolean) => void
  isReady?: boolean
}
export const VirtualCard = ({ setAppeared, isReady }: VirtualCardProps) => {
  const isMobile = useIsMobile()
  const { locale } = useParams()

  const windowHeight = useWindowHeight()
  const calculateVisibleHeight = useCalculateVisibleHeight()
  const imageRef = useRef<HTMLElement | null>(null)
  const scale = useDynamicScrollTransform(imageRef.current, IMAGE_SCALE_START, IMAGE_SCALE_END)

  const [imgAppeared, setImgAppeared] = useState(false)

  const { scrollY } = useVerticalScrollWithThrottle()
  const { scrollY: imgScrollY } = useScroll()

  /* Mobile */
  const mobileCardsControls = useAnimation()
  const yOffsetMax = useDynamicYOffsetMax(imageRef?.current)
  const yOffset = useTransform(imgScrollY, [0, 2400], [0, yOffsetMax], { clamp: false })
  const [cardsRef, cardsInView] = useInView({ threshold: 0.1 })

  const titleControls = useAnimation()
  const [titleRef, titleRefInView] = useInView({ threshold: 0.3 })

  const descriptionControls = useAnimation()

  /* Desktop */
  const wrapperControls = useAnimation()
  const leftWrapperControls = useAnimation()
  const rightWrapperControls = useAnimation()

  const desktopScale = useTransform(
    imgScrollY,
    [0, 1000],
    [DESKTOP_IMAGE_SCALE_START, DESKTOP_IMAGE_SCALE_END],
    { clamp: false },
  )

  const leftWrapperRef = useRef<HTMLDivElement>(null)
  const rightWrapperRef = useRef<HTMLDivElement>(null)

  const [isStopped, setIsStopped] = useState(false)
  const [prismicData, setPrismicData] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const productItemId =
        locale === 'en'
          ? 'ZiomSBAAAOYdZ-N-'
          : locale === 'fi'
            ? 'ZiomYRAAAOYdZ-Px'
            : 'ZiomZhAAAOYdZ-QQ'

      const data =
        await prismicClient.getByID(productItemId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])

  useEffect(() => {
    const calculateVerticalShift = () => {
      const visibleHeight = calculateVisibleHeight(imageRef?.current)
      const elementHeight = imageRef?.current?.getBoundingClientRect().height ?? 0
      const currentViewportWidth = window.innerWidth - VIEWPORT_PADDING
      const shiftCoefficient = visibleHeight / elementHeight

      let shift

      if (isElementBottomInView(imageRef?.current)) {
        shift = -MAX_SHIFT_COEFFICIENT * currentViewportWidth
      } else if (shiftCoefficient <= IMAGE_SHIFT_START) {
        shift = 0
      } else {
        shift =
          ((shiftCoefficient - IMAGE_SHIFT_START) *
            (MAX_SHIFT_COEFFICIENT * currentViewportWidth)) /
          (IMAGE_SHIFT_START - IMAGE_SHIFT_END)
      }

      return shift
    }

    const sequence = async () => {
      if (!imgAppeared) {
        const shift = calculateVerticalShift()
        await mobileCardsControls.start({
          translateX: -shift,
          transition: { ease: 'linear', duration: 0.1 },
        })
        await mobileCardsControls.start({
          opacity: 1,
          transition: { duration: 0.3, delay: 0.8 },
        })
        setImgAppeared(true)
        return
      }

      const shift = calculateVerticalShift()
      mobileCardsControls.start({
        translateX: -shift,
        transition: { ease: 'linear', duration: 0.1 },
      })

      mobileCardsControls.start({
        scale: scale.get() > IMAGE_SCALE_END ? scale.get() : IMAGE_SCALE_END,
        translateY: yOffset.get(),
      })
    }

    if (cardsInView && isMobile && isReady) {
      sequence()
    }
  }, [cardsInView, isMobile, mobileCardsControls, scrollY, windowHeight, imgAppeared, isReady])

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await titleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await descriptionControls.start({ opacity: 1, transition: { duration: 0.3 } })
    }
    if (isMobile && titleRefInView && imgAppeared) {
      mobileHeroSequence()
    }
  }, [isMobile, titleRefInView, imgAppeared])

  useEffect(() => {
    const desktopSequence = async () => {
      await wrapperControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.6 } })
      setAppeared?.(true)
    }
    if (!isMobile) {
      desktopSequence()
    }
  }, [isMobile, wrapperControls])

  useEffect(() => {
    const sequence = async () => {
      const scale =
        desktopScale.get() > DESKTOP_IMAGE_SCALE_END
          ? desktopScale.get() > DESKTOP_IMAGE_SCALE_START
            ? DESKTOP_IMAGE_SCALE_START
            : desktopScale.get()
          : DESKTOP_IMAGE_SCALE_END
      leftWrapperControls.start({
        scale,
      })

      // get intermediate horizontal shift of the left wrapper with interpolation function:
      // y = y0 + (x - x0) * ((y1 - y0) / (x1 - x0))
      // In this case:
      // y0 = DESKTOP_IMAGE_VERTICAL_SHIFT_START
      // x0 = DESKTOP_IMAGE_SCALE_START
      // y1 = DESKTOP_IMAGE_VERTICAL_SHIFT_END
      // x1 = DESKTOP_IMAGE_SCALE_END
      // x = scale
      // hence:

      // same logic for margin left shift
      const marginLeftShift =
        DESKTOP_IMAGE_HORIZONTAL_SHIFT_START +
        (scale - DESKTOP_IMAGE_SCALE_START) *
          ((DESKTOP_IMAGE_HORIZONTAL_SHIFT_END - DESKTOP_IMAGE_HORIZONTAL_SHIFT_START) /
            (DESKTOP_IMAGE_SCALE_END - DESKTOP_IMAGE_SCALE_START))

      if (imageRef.current) {
        imageRef.current.style.paddingLeft = `${marginLeftShift}px`
      }

      if (scale === DESKTOP_IMAGE_SCALE_END) {
        if (!isStopped) {
          document.body.style.overflowY = 'hidden'
          setTimeout(() => {
            document.body.style.overflowY = 'auto'
            setIsStopped(true)
          }, 500)
        }
        rightWrapperControls.start({
          opacity: 1,
          y: 0,
          transition: { duration: 0.3, ease: 'linear' },
        })
      } else {
        rightWrapperControls.start({
          opacity: 0,
          y: DESKTOP_ANIMATION_SHIFT,
          transition: { duration: 0.1, ease: 'linear' },
        })
      }
    }

    if (!isMobile && isReady) {
      sequence()
    }
  }, [isMobile, rightWrapperControls, scrollY, isReady])

  const setRefs = (node: HTMLElement | null) => {
    imageRef.current = node
    cardsRef(node)
  }

  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack gap={12}>
          <MobileCards
            ref={setRefs}
            animate={mobileCardsControls}
            initial={{ opacity: 0, x: 0, y: 100, scale: IMAGE_SCALE_START }}
            src="/images/virtual_card.png"
          />
          <Stack gap={7.5}>
            <MobileSubTitle ref={titleRef} animate={titleControls} initial={{ opacity: 0 }}>
              {prismicData?.data.product_header[0]?.text}
            </MobileSubTitle>
            <MobileDescription animate={descriptionControls} initial={{ opacity: 0 }}>
              {prismicData?.data.product_description[0]?.text}
            </MobileDescription>
          </Stack>
        </Stack>
      </MobileWrapper>
    )
  }
  return (
    <Wrapper
      animate={wrapperControls}
      ref={setRefs}
      initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}
    >
      <Inline verticalalign={Alignments.Center} align={Alignments.Center} gap={15}>
        <LeftWrapper
          ref={leftWrapperRef}
          animate={leftWrapperControls}
          initial={{ scale: DESKTOP_IMAGE_SCALE_START }}
        >
          <AppImage src="/images/virtual_card.png" />
        </LeftWrapper>
        <RightWrapper
          ref={rightWrapperRef}
          animate={rightWrapperControls}
          initial={{ y: DESKTOP_ANIMATION_SHIFT, opacity: 0 }}
        >
          <Stack gap={7.5} align={Alignments.Left}>
            <SubTitle>{prismicData?.data.product_header[0]?.text}</SubTitle>
            <Description>{prismicData?.data.product_description[0]?.text}</Description>
          </Stack>
        </RightWrapper>
      </Inline>
    </Wrapper>
  )
}
