import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Stack } from '../../common/components/Spacing'
import { Description, SubTitle } from '../../common/styled'
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg'

import { useIsMobile } from '../../common/hooks'
import { useWindowHeight } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { DESKTOP_ANIMATION_SHIFT } from '../../common/constants'
import { getCurrentLocale } from '../../../config/locales'
import { prismicClient } from '../../../config/prismicClient'
import { useParams } from 'react-router-dom'

type WrapperProps = {
  isMobile: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw`w-[900px] mx-auto`}
  ${({ isMobile }) => isMobile && tw`w-[100%]`}
`
export const TableItemWrapper = tw.div`w-full border-0 border-b  border-solid border-zevoyGray1`
export const TableDescription = tw(Description)`text-[14px] leading-[20px]`
export const TableItem = tw.div`flex flex-row justify-between items-start pb-5  cursor-pointer`
export const Question = styled(SubTitle)`
  ${tw`text-[24px] leading-[110.5%]`}
  ${({ isMobile }) => isMobile && tw`text-[20px] w-[312px]`}
`
export const TableItemContent = styled.div<{ isOpen: boolean }>`
  ${tw`overflow-hidden transition-all duration-200 ease-linear`}
  ${({ isOpen }) => (isOpen ? tw`max-h-[500px] mb-5 opacity-100` : tw`max-h-0 opacity-0`)}
`

export const TableItemDescription = styled(Description)`
  ${tw`text-[15px] leading-[21px]`}
`

export const ThingsToKnow = () => {
  const isMobile = useIsMobile()
  const { locale } = useParams<string>()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const [thingsToKnowRef, thingsToKnowInView] = useInView({
    threshold: (windowHeight > 900) ? 0.3 : 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const controls = useAnimation()
  const [prismicData, setPrismicData] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale
      const pageId =
        locale === 'en'
          ? 'ZiZ9whAAAJu0V6zd'
          : locale === 'fi'
            ? 'ZiZ-CRAAAA61V65p'
            : 'ZiZ-DRAAAMO1V66A'

      const data =
        await prismicClient.getByID(pageId, {
          lang: prismicLocale,
        })
      setPrismicData(data)
    }

    getPrismicData()
  }, [locale])
  useEffect(() => {
    if (thingsToKnowInView) {
      if (isMobile) {
        controls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
        return
      }

      controls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [isMobile, thingsToKnowInView])

  const [isQuestion1Open, setIsQuestion1Open] = useState(false)
  const [isQuestion2Open, setIsQuestion2Open] = useState(false)
  const [isQuestion3Open, setIsQuestion3Open] = useState(false)
  const [isQuestion4Open, setIsQuestion4Open] = useState(false)
  const [isQuestion5Open, setIsQuestion5Open] = useState(false)

  const closeAll = () => {
    setIsQuestion1Open(false)
    setIsQuestion2Open(false)
    setIsQuestion3Open(false)
    setIsQuestion4Open(false)
    setIsQuestion5Open(false)
  }

  const translations = {
    title: prismicData?.data.faq_header[0]?.text,
    description: prismicData?.data.faq_description[0]?.text,
    question1: prismicData?.data.question_1[0]?.text,
    answer1: prismicData?.data.answer_1[0]?.text,
    question2: prismicData?.data.question_2[0]?.text,
    answer2: prismicData?.data.answer_2[0]?.text,
    question3: prismicData?.data.question_3[0]?.text,
    answer3: prismicData?.data.answer_3[0]?.text,
    question4: prismicData?.data.question_4[0]?.text,
    answer4: prismicData?.data.answer_4[0]?.text,
    question5: prismicData?.data.question_5[0]?.text,
    answer5: prismicData?.data.answer_5[0]?.text,
  }
  return (
    <Wrapper
      animate={controls}
      ref={thingsToKnowRef}
      isMobile={!!isMobile}
      initial={isMobile ? { opacity: 0 } : { opacity: 0, y: -DESKTOP_ANIMATION_SHIFT }}
    >
      <Stack gap={7.5}>
        <SubTitle isMobile={isMobile}>{translations.title}</SubTitle>
        <TableDescription isMobile={isMobile}>{translations.description}</TableDescription>
        <Stack gap={5}>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion1Open(!isQuestion1Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question1}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion1Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer1}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion2Open(!isQuestion2Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question2}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion2Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer2}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion3Open(!isQuestion3Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question3}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion3Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer3}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion4Open(!isQuestion4Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question4}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion4Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer4}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
          <TableItemWrapper>
            <TableItem
              onClick={() => {
                closeAll()
                setIsQuestion5Open(!isQuestion5Open)
              }}
            >
              <Question isMobile={isMobile}>{translations.question5}</Question>
              <Plus />
            </TableItem>
            <TableItemContent isOpen={isQuestion5Open}>
              <TableItemDescription isMobile={isMobile}>{translations.answer5}</TableItemDescription>
            </TableItemContent>
          </TableItemWrapper>
        </Stack>
      </Stack>
    </Wrapper>
  )
}
