import tw from 'twin.macro'
import { NavLink, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Inline, Stack } from '../../common/components/Spacing'
import { Alignments, ComponentVariants, DESKTOP_ANIMATION_SHIFT, Sizes } from '../../common/constants'
import { Button } from '../../common/components/Buttons'
import { HeroTitle } from '../../common/styled'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import { getCurrentLocale } from '../../../config/locales'
import { prismicClient } from '../../../config/prismicClient'

const Wrapper = tw.div`mt-[250px] text-center w-[800px] mx-auto relative z-10 mb-[120px]`


const HeroDescription = tw.h2`text-xl text-[20px] font-light m-0`
const HeroButtons = tw.div``

const MobileWrapper = tw.div`relative z-10 mt-[125px] mb-[80px]`
const MobileHeroTitle = tw(HeroTitle)`text-[40px] leading-[105%] w-full`
const MobileDescription = tw(HeroDescription)`text-[14px] leading-[120%]`

type HeroSectionProps = {
  setHeroSectionAppeared: (hasAppeared: boolean) => void
}
export const HeroSection = ({ setHeroSectionAppeared }: HeroSectionProps) => {
  const { locale } = useParams()
  const [prismicData, setPrismicData] = useState<any>(null)

  const isMobile = useIsMobile()

  const mobileTitleControls = useAnimation()
  const mobileDescriptionControls = useAnimation()

  const wrapperControls = useAnimation()

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const customerCasesData = await prismicClient.getSingle('customer_pag', {
        lang: prismicLocale,
      })
      setPrismicData(customerCasesData)
    }
    getPrismicData()
  }, [locale])

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await mobileTitleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await mobileDescriptionControls.start({ opacity: 1, transition: { duration: 0.3 } })
      setHeroSectionAppeared(true)
    }

    const desktopHeroSequence = async () => {
      await wrapperControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
      setTimeout(() => {
        setHeroSectionAppeared(true)
      }, 800)
    }
    if (isMobile) {
      mobileHeroSequence()
    } else {
      desktopHeroSequence()
    }
  }, [isMobile])

  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack align={Alignments.Left} gap={5}>
          <motion.div animate={mobileTitleControls} initial={{ opacity: 0 }}>
            <MobileHeroTitle>{prismicData?.data?.company[0].text}</MobileHeroTitle>
          </motion.div>
          <motion.div animate={mobileDescriptionControls} initial={{ opacity: 0 }}>
            <Stack gap={10}>
              <MobileDescription>{prismicData?.data?.company_description[0].text}</MobileDescription>
              <HeroButtons>
                <Inline align={Alignments.Left}>
                  <NavLink to={`/${locale}/contact`}>
                    <Button variant={ComponentVariants.Primary} size={Sizes.Medium}>
                      {prismicData?.data?.contact_sales[0].text}
                    </Button>
                  </NavLink>
                </Inline>
              </HeroButtons>
            </Stack>
          </motion.div>
        </Stack>
      </MobileWrapper>
    )
  }

  return (
    <motion.div animate={wrapperControls} initial={{ y: DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
      <Wrapper>
        <Stack align={Alignments.Center} gap={10}>
          <HeroTitle>{prismicData?.data?.company[0].text}</HeroTitle>
          <Stack gap={7.5}>
            <HeroDescription>{prismicData?.data?.company_description[0].text}</HeroDescription>
            <HeroButtons>
              <Inline align={Alignments.Center}>
                <NavLink to={`/${locale}/contact`}>
                  <Button variant={ComponentVariants.Primary} size={Sizes.Medium}>
                    {prismicData?.data?.contact_sales[0].text}
                  </Button>
                </NavLink>
              </Inline>
            </HeroButtons>
          </Stack>
        </Stack>
      </Wrapper>
    </motion.div>
  )
}
