import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useIsMobile } from '../common/hooks'
import { Navigation } from '../Navigation'
import { MWrapper } from '../common/styled'
import { Stack } from '../common/components/Spacing'
import { Footer } from '../Footer'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { HeroSection } from './components/HeroSection'
import { MerchantCards } from './components/MerchantCards'
import { MerchantItems } from './components/MerchantItems'
import { ThingsToKnow } from './components/ThingsToKnow'
import { JoinTheNetwork } from './components/JoinTheNetwork'

export const ForMerchants = () => {
  const { locale } = useParams<string>()
  const isMobile = useIsMobile()
  const [prismicData, setPrismicData] = useState<any>(null)
  const [cardsReady, setCardsReady] = useState<boolean>(false)

  useEffect(() => {
      const getPrismicData = async () => {
          const localeData = getCurrentLocale(locale ?? '');
          const prismicLocale = localeData.prismicLocale;

          const result = await prismicClient.getSingle('for_merchants_page', {
              lang: prismicLocale,
          });
          setPrismicData(result)
      }
      getPrismicData()
  }, [locale])

  if (!prismicData) {
    return null
  }
  return (
    <div>
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={isMobile ? 15 : 25}>
          <HeroSection
            translations={{
            heroTitle: prismicData?.data?.merchants_headline?.[0]?.text,
            heroDescription: prismicData?.data?.merchants_introduction?.[0]?.text,
            heroCta: prismicData?.data?.merchants_cta?.[0]?.text
          }} />
          <MerchantCards setCardsReady={setCardsReady}/>
          <MerchantItems
            cardsReady={cardsReady}
            translations={{
              items: [
                {
                  title: prismicData?.data?.headline1?.[0]?.text,
                  description: prismicData?.data?.description1?.[0]?.text
                },
                {
                  title: prismicData?.data?.headline2?.[0]?.text,
                  description: prismicData?.data?.description2?.[0]?.text
                },
                {
                  title: prismicData?.data?.headline3?.[0]?.text,
                  description: prismicData?.data?.description3?.[0]?.text
                }
              ]
            }}/>
          <JoinTheNetwork
            translations={{
              title: prismicData?.data?.join_headline?.[0]?.text,
              description: prismicData?.data?.join_description_new?.[0]?.text,
              cta: prismicData?.data?.next?.[0]?.text,
              thankYouTitle: prismicData?.data?.thank_you_title?.[0]?.text,
              thankYouDescription: prismicData?.data?.thank_you_description?.[0]?.text,
              disclaimer: prismicData?.data?.disclaimer?.[0]?.text,
              disclaimer_link: prismicData?.data?.disclaimer_link?.[0]?.text,
              company_name: prismicData?.data?.company_name?.[0]?.text,
              business_id: prismicData?.data?.business_id?.[0]?.text,
              email: prismicData?.data?.email?.[0]?.text,
              phone: prismicData?.data?.phone?.[0]?.text,
              amount_of_restaurants: prismicData?.data?.amount_of_restaurants?.[0]?.text,
            }}
          />
          <ThingsToKnow
            isMobile={!!isMobile}
            translations={{
              title: prismicData?.data?.things_to_know_headline?.[0]?.text,
              description: prismicData?.data?.things_to_know_description?.[0]?.text,
              question1: prismicData?.data?.question1?.[0]?.text,
              answer1: prismicData?.data?.answer1?.[0]?.text,
              question2: prismicData?.data?.question2?.[0]?.text,
              answer2: prismicData?.data?.answer2?.[0]?.text,
              question3: prismicData?.data?.question3?.[0]?.text,
              answer3: prismicData?.data?.answer3?.[0]?.text,
              question4: prismicData?.data?.question4?.[0]?.text,
              answer4: prismicData?.data?.answer4?.[0]?.text,
              question5: prismicData?.data?.question5?.[0]?.text,
              answer5: prismicData?.data?.answer5?.[0]?.text,
              question6: prismicData?.data?.question6?.[0]?.text,
              answer6: prismicData?.data?.answer6?.[0]?.text
            }}
            />
        </Stack>
        <Footer />
      </MWrapper>
    </div>
  )
}