import React, { useEffect, useState } from 'react'
import { Stack } from '../common/components/Spacing'
import { Alignments, DESKTOP_ANIMATION_SHIFT, SolutionItemIds } from '../common/constants'
import tw, { styled } from 'twin.macro'
import { SimplestSolutionTile } from './components/SimplestSolutionTile'
import { useParams } from 'react-router-dom'
import { TextBig, TextHighlight } from '../common/styled'
import { useIsMobile } from '../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useWindowHeight } from '@react-hook/window-size'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { LocaleIndex } from '../common/types'

// const Wrapper = tw.div`mt-[300px]`
const Wrapper = tw.div``
const Title = tw(TextBig)`
    w-[900px] text-[64px] text-center
`

const Tiles = tw(motion.div)`flex flex-wrap justify-center items-center gap-[30px] items-end`

const MobileTitle = styled(TextBig)`
  ${tw`text-[52px] text-left leading-[51px]`}
`

type SimplestSolutionProps = {
  shouldStartAnimation?: boolean
  showWithHero?: boolean
}
export const SimplestSolution = ({ shouldStartAnimation, showWithHero }: SimplestSolutionProps) => {
  const isMobile = useIsMobile()
  const { locale } = useParams()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? showWithHero ? '0px' : `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`

  const mobileTitleControls = useAnimation()
  const titleControls = useAnimation()

  const [mobileTitleRef, mobileTitleInView] = useInView({
    threshold: showWithHero ? 0 : 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [titleRef, titleInView] = useInView({
    threshold: 0,
    rootMargin: `${showWithHero ? '0px' : appearanceRatio} 0px 0px 0px`,
  })
  const [tilesRef, tilesInView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [tiles2Ref, tiles2InView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [tiles3Ref, tiles3InView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })

  const firstTileControls = useAnimation()
  const secondTileControls = useAnimation()
  const thirdTileControls = useAnimation()
  const fourthTileControls = useAnimation()
  const fifthTileControls = useAnimation()

  const [expensesTranslations, setExpensesTranslations] = useState<any>(null)
  const [subscriptionsTranslations, setSubscriptionsTranslations] = useState<any>(null)
  const [claimsTranslations, setClaimsTranslations] = useState<any>(null)
  const [benefitsTranslations, setBenefitsTranslations] = useState<any>(null)
  const [customTranslations, setCustomTranslations] = useState<any>(null)
  const [titleTranslations, setTitleTranslations] = useState<any>(null)
  let isMounted = true;

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const solutionItems =
        await prismicClient.getAllByType('solution_item', {
        lang: prismicLocale,
      })

      const solutionHeader =
        await prismicClient.getSingle('solution_header', {
        lang: prismicLocale,
      })

      const expensesItem =
        solutionItems.find((item: any) =>
          Object.keys(SolutionItemIds.expenses).some((key) =>
            SolutionItemIds.expenses[key as LocaleIndex] === item.id))

      const subscriptionsItem =
        solutionItems.find((item: any) =>
          Object.keys(SolutionItemIds.subscriptions).some((key) =>
            SolutionItemIds.subscriptions[key as LocaleIndex] === item.id))

      const claimsItem =
        solutionItems.find((item: any) =>
          Object.keys(SolutionItemIds.claims).some((key) =>
            SolutionItemIds.claims[key as LocaleIndex] === item.id))

      const benefitsItem =
        solutionItems.find((item: any) =>
          Object.keys(SolutionItemIds.benefits).some((key) =>
            SolutionItemIds.benefits[key as LocaleIndex] === item.id))

      const customItem =
        solutionItems.find((item: any) =>
          Object.keys(SolutionItemIds.custom).some((key) =>
            SolutionItemIds.custom[key as LocaleIndex] === item.id))

      setExpensesTranslations(expensesItem)
      setSubscriptionsTranslations(subscriptionsItem)
      setClaimsTranslations(claimsItem)
      setBenefitsTranslations(benefitsItem)
      setCustomTranslations(customItem)
      setTitleTranslations(solutionHeader)
    }

    getPrismicData()
  }, [locale])

  useEffect(() => {
    const mobileSequence = async () => {
      await mobileTitleControls.start('visible')
    }

    if (!isMobile) {
      return
    }
    if (mobileTitleInView && shouldStartAnimation) {
      mobileSequence()
    }
  }, [mobileTitleInView, mobileTitleControls, isMobile, shouldStartAnimation])

  useEffect(() => {
    if (isMobile) {
      return
    }
    if (titleInView && shouldStartAnimation) {
      titleControls.start({
        opacity: 1,
        transition: { duration: 0.5, delay: 0.6 },
      })
    }
  }, [titleInView, titleControls, isMobile, shouldStartAnimation])

  useEffect(() => {
    const desktopSequence = async () => {
      if (isMounted) {
        await firstTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } })
      }
    }

    if (isMobile) {
      return
    }

    if (tilesInView) {
      desktopSequence()
    }

    return () => {
      isMounted = false
    }
  }, [tilesInView, firstTileControls, isMobile])

  useEffect(() => {
    const desktopSequence = async () => {
      if (isMounted) {
        await secondTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.6 } })
      }
    }

    if (isMobile) {
      return
    }

    if (tilesInView) {
      desktopSequence()
    }

    return () => {
      isMounted = false
    }
  }, [tilesInView, secondTileControls, isMobile])

  useEffect(() => {

    const desktopSequence = async () => {
      if (isMounted) {
        await thirdTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.5 } })
      }
    }

    if (isMobile) {
      return
    }

    if (tiles2InView) {
      desktopSequence()
    }

    return () => {
      isMounted = false
    }
  }, [tiles2InView, thirdTileControls, isMobile])

  useEffect(() => {

    const desktopSequence = async () => {
      if (isMounted) {
        await fourthTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.8 } })
      }
    }

    if (isMobile) {
      return
    }

    if (tiles2InView) {
      desktopSequence()
    }

    return () => {
      isMounted = false
    }
  }, [tiles2InView, fourthTileControls, isMobile])

  useEffect(() => {

    const desktopSequence = async () => {
      if (isMounted) {
        await fifthTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.8 } })
      }
    }

    if (isMobile) {
      return
    }

    if (tiles3InView) {
      desktopSequence()
    }

    return () => {
      isMounted = false
    }
  }, [tiles3InView, fifthTileControls, isMobile])


  // avoiding glitches
  if (!expensesTranslations || !subscriptionsTranslations || !claimsTranslations || !customTranslations || !titleTranslations || !benefitsTranslations) {
    return null
  }

  if (isMobile) {
    const wordsPart1 = titleTranslations.data.solution_header_start[0]?.text?.split(' ') ?? []
    const wordsHighlighting = titleTranslations.data.solution_header_color[0]?.text?.split(' ') ?? []
    const wordsPart2 = titleTranslations.data.solution_header_end[0]?.text?.split(' ') ?? []
    const containerVariants = {
      visible: {
        opacity: 1,
        transition: {
          delay: 0.3,
          staggerChildren: 0.5,
        },
      },
      hidden: { opacity: 0 },
    }

    const childVariants = (i: number) => ({
      visible: {
        opacity: 1,
        x: 0,
        transition: { delay: i * 0.2, duration: 0.8, type: 'linear', stiffness: 100 },
      },
      hidden: { opacity: 0, x: -200 },
    })

    return (
      <Wrapper>
        <Stack gap={20} align={Alignments.Left}>
          <MobileTitle ref={mobileTitleRef}>
            <motion.div initial="hidden" animate={mobileTitleControls} variants={containerVariants}>
              {wordsPart1.map((word: string, index: number) => (
                <motion.span key={index} variants={childVariants(index)}>
                  {word}{' '}
                </motion.span>
              ))}
              {wordsHighlighting.map((word: string, index: number) => (
                <TextHighlight key={index} variants={childVariants(wordsPart1.length)}>
                  {word}{' '}
                </TextHighlight>
              ))}
              {wordsPart2.map((word: string, index: number) => (
                <motion.span key={index} variants={childVariants(wordsPart1.length + 1 + index)}>
                  {word}{' '}
                </motion.span>
              ))}
            </motion.div>
          </MobileTitle>
          <Stack gap={5}>
            <SimplestSolutionTile
              title={expensesTranslations.data.solution_headline[0].text}
              subTitle={expensesTranslations.data['solution_sub-headline'][0].text}
              description={expensesTranslations.data.solution_description[0].text}
              image={'/images/expenses.png'}
              ctaText={expensesTranslations.data.find_out_more[0].text}
              ctaLink={`/${locale}/expenses`}
              // imageWidth="270px"
              index={0}
              imageMarginTop="20px"
              imageMarginLeft="38px"
              threshold={showWithHero ? 0 : 0.2}
              delay={showWithHero ? 1.2 : 0.2}
              shouldStartAnimation={shouldStartAnimation}
            />
            <SimplestSolutionTile
              title={subscriptionsTranslations.data.solution_headline[0].text}
              subTitle={subscriptionsTranslations.data['solution_sub-headline'][0].text}
              description={subscriptionsTranslations.data.solution_description[0].text}
              image={'/images/subscriptions.png'}
              ctaText={subscriptionsTranslations.data.find_out_more[0].text}
              ctaLink={`/${locale}/subscriptions`}
              // imageWidth="273px"
              index={1}
              imageMarginTop="14px"
              imageMarginLeft="52px"
              shouldStartAnimation={shouldStartAnimation}
            />
            <SimplestSolutionTile
              title={claimsTranslations.data.solution_headline[0].text}
              subTitle={claimsTranslations.data['solution_sub-headline'][0].text}
              description={claimsTranslations.data.solution_description[0].text}
              image={'/images/claims.png'}
              ctaText={claimsTranslations.data.find_out_more[0].text}
              ctaLink={`/${locale}/claims`}
              // imageWidth="276px"
              index={2}
              imageMarginTop="22px"
              imageMarginLeft="46px"
              shouldStartAnimation={shouldStartAnimation}
            />
            <SimplestSolutionTile
              title={benefitsTranslations?.data.solution_headline[0].text ?? ''}
              subTitle={benefitsTranslations?.data['solution_sub-headline'][0].text ?? ''}
              description={benefitsTranslations?.data.solution_description[0].text ?? ''}
              image={'/images/benefits_tile.png'}
              ctaText={benefitsTranslations?.data.find_out_more[0].text ?? ''}
              ctaLink={`/${locale}/benefits`}
              // imageWidth="256px"
              index={2}
              imageMarginTop="0px"
              imageMarginLeft="46px"
              shouldStartAnimation={shouldStartAnimation}
            />
            <SimplestSolutionTile
              title={customTranslations.data.solution_headline[0].text}
              subTitle={customTranslations.data['solution_sub-headline'][0].text}
              description={customTranslations.data.solution_description[0].text}
              image={'/images/custom.png'}
              ctaText={customTranslations.data.find_out_more[0].text}
              ctaLink={`/${locale}/contact`}
              // imageWidth="335px"
              index={3}
              imageMarginTop="10px"
              imageMarginLeft="15px"
              shouldStartAnimation={shouldStartAnimation}
            />
          </Stack>
        </Stack>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Stack gap={15} align={Alignments.Center}>
        <Title
          ref={titleRef}
          animate={titleControls}
          initial={{ opacity: 0, y: DESKTOP_ANIMATION_SHIFT }}
        >
          {titleTranslations.data.solution_header_start[0]?.text ? titleTranslations.data.solution_header_start[0].text + ' ' : ''}
          <TextHighlight>{titleTranslations.data.solution_header_color[0]?.text ?? ''}</TextHighlight>
          {titleTranslations.data.solution_header_end[0]?.text ? ' ' + titleTranslations.data.solution_header_end[0].text : ''}
        </Title>
        <Tiles ref={tilesRef}>
          <SimplestSolutionTile
            title={expensesTranslations.data.solution_headline[0].text}
            subTitle={expensesTranslations.data['solution_sub-headline'][0].text}
            description={expensesTranslations.data.solution_description[0].text}
            image={'/images/expenses.png'}
            ctaText={expensesTranslations.data.find_out_more[0].text}
            ctaLink={`/${locale}/expenses`}
            index={0}
            controls={firstTileControls}
          />
          <SimplestSolutionTile
            title={subscriptionsTranslations.data.solution_headline[0].text}
            subTitle={subscriptionsTranslations.data['solution_sub-headline'][0].text}
            description={subscriptionsTranslations.data.solution_description[0].text}
            image={'/images/subscriptions.png'}
            ctaText={subscriptionsTranslations.data.find_out_more[0].text}
            ctaLink={`/${locale}/subscriptions`}
            index={1}
            controls={secondTileControls}
          />
        </Tiles>
        <Tiles ref={tiles2Ref}>
          <SimplestSolutionTile
            title={claimsTranslations.data.solution_headline[0].text}
            subTitle={claimsTranslations.data['solution_sub-headline'][0].text}
            description={claimsTranslations.data.solution_description[0].text}
            image={'/images/claims.png'}
            ctaText={claimsTranslations.data.find_out_more[0].text}
            ctaLink={`/${locale}/claims`}
            index={2}
            controls={thirdTileControls}
            descriptionHeight="105px"
          />
          <SimplestSolutionTile
            title={benefitsTranslations?.data.solution_headline?.[0]?.text ?? ''}
            subTitle={benefitsTranslations?.data['solution_sub-headline']?.[0]?.text ?? ''}
            description={benefitsTranslations?.data.solution_description?.[0]?.text ?? ''}
            image={'/images/benefits_tile.png'}
            ctaText={benefitsTranslations?.data.find_out_more?.[0]?.text ?? ''}
            ctaLink={`/${locale}/benefits`}
            index={3}
            controls={fourthTileControls}
          />
        </Tiles>
        <Tiles ref={tiles3Ref}>
          <SimplestSolutionTile
            title={customTranslations.data.solution_headline[0].text}
            subTitle={customTranslations.data['solution_sub-headline'][0].text}
            description={customTranslations.data.solution_description[0].text}
            image={'/images/custom.png'}
            ctaText={customTranslations.data.find_out_more[0].text}
            ctaLink={`/${locale}/contact`}
            index={3}
            controls={fifthTileControls}
          />
        </Tiles>
      </Stack>
    </Wrapper>
  )
}
