import React from 'react'
import tw, { styled, theme } from 'twin.macro'
import { ReactComponent as Caret } from '../../../../assets/icons/caret.svg'
import { Inline, Stack } from '../Spacing'
import { Alignments } from '../../constants'

const NavItem = styled.div`
  ${tw`relative text-sm`}
`

export type DropdownItem = {
  label: string
  labelShort?: string
  href: string
}

const CaretIcon = tw(Caret)`w-[16px] h-[16px]`
const DropdownContent = styled.div`
  ${tw`absolute left-0 bg-zevoyBlueBlack shadow-lg opacity-0 transform scale-95 -ml-2 rounded-[15px] p-[20px] -ml-[23px] -mt-[52px] pr-[40px]`}
  ${tw`transition-all duration-500 ease-in-out`}
${tw`border-solid border-1 border-zevoyWhite border-opacity-10  z-100 `}
  pointer-events: none;
  background: radial-gradient(
      157.15% 80.25% at 50% 50%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 98.96%
    ),
    linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%);
  background-color: rgb(255 255 255 / 0.05);

  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  ${NavItem}:hover & {
    ${tw`opacity-100 transform scale-100 text-opacity-100`}
    pointer-events: auto;
  }
`

const DropdownLink = styled.a`
  ${tw`relative max-w-max inline-block text-[20px] cursor-pointer hover:text-zevoyWhite first:rounded-t-md last:rounded-b-md whitespace-nowrap`}
  ${tw`transition-colors duration-200 ease-in-out -tracking-[1px]`}
    font-family: "Slussen-Medium", sans-serif;
  font-weight: 500;
  &::after {
    ${tw`absolute left-0 h-[2px] rounded-md transition-all duration-500 ease-in-out`}
    content: "";
    background: linear-gradient(
      to right,
      ${theme('colors.electricViolet')},
      ${theme('colors.vividBlue')}
    );
    width: 0;
    bottom: -3px;
  }
  &:hover::after {
    width: 100%;
  }
`

type WrapperProps = {
  selected?: boolean
}
const DropdownLabel = styled.div<WrapperProps>`
  ${tw`text-sm relative block mb-2 mt-2 cursor-pointer    transition-all duration-500 ease-in-out`}

  &::after {
    ${tw`absolute left-0 h-[1px] rounded-md transition-all duration-500 ease-in-out`}
    content: "";
    background: linear-gradient(
            to right,
            ${theme('colors.electricViolet')},
            ${theme('colors.vividBlue')}
    );
    bottom: -7px;
    width: 0;
    ${({ selected }) => selected && tw`w-full`}
  ${NavItem}:hover & {
    ${tw`opacity-0`}
  }
`

const DropdownContentLabel = tw.div`cursor-default  mb-2.5 ml-[1.5px] mt-[2px]`

type DropdownMenuProps = {
  items: DropdownItem[]
  label: string
  menuLabel?: string
  selected?: boolean
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  label,
  menuLabel,
  selected,
}) => {
  return (
    <NavItem>
      <DropdownLabel selected={selected}>
        <Inline verticalalign={Alignments.Center} gap={1}>
          <span>{label}</span>
          <CaretIcon />
        </Inline>
      </DropdownLabel>
      <DropdownContent>
        <DropdownContentLabel>
          <Inline verticalalign={Alignments.Center} gap={1}>
            <span>{menuLabel ?? label}</span>
            <CaretIcon />
          </Inline>
        </DropdownContentLabel>
        <Stack gap={2}>
          {items.map((item, index) => (
            <DropdownLink key={index} href={item.href}>
              {item.label}
            </DropdownLink>
          ))}
        </Stack>
      </DropdownContent>
    </NavItem>
  )
}
