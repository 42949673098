export const BASE_URL = process.env.REACT_APP_NEXT_PUBLIC_BASE_URL
export const GTM = process.env.REACT_APP_NEXT_PUBLIC_GTM
export const HUBSPOT_FORM_URL = process.env.REACT_APP_HUBSPOT_FORM_URL
export const HUBSPOT_PORTAL_ID = process.env.REACT_APP_HUBSPOT_PORTAL_ID
export const HUBSPOT_GETSTARTED_FORM_ID = process.env.REACT_APP_HUBSPOT_GETSTARTED_FORM_ID
export const HUBSPOT_NEWGETSTARTED_FORM_ID = process.env.REACT_APP_HUBSPOT_NEWGETSTARTED_FORM_ID
export const HUBSPOT_RECEIVEADEMO_FORM_ID = process.env.REACT_APP_HUBSPOT_RECEIVEADEMO_FORM_ID
export const HUBSPOT_CAMPAIGN_FORM_ID = process.env.REACT_APP_HUBSPOT_CAMPAIGN_FORM_ID
export const COOKIEHUB_JS = process.env.REACT_APP_NEXT_PUBLIC_COOKIEHUB_JS
export const ENV = process.env.REACT_APP_NEXT_PUBLIC_VERCEL_ENV
export const VERCEL = process.env.REACT_APP_NEXT_PUBLIC_VERCEL
export const VERCEL_URL = process.env.REACT_APP_NEXT_PUBLIC_VERCEL_URL
export const VERCEL_GIT_BRANCH = process.env.REACT_APP_NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF
export const TABLET_BREAKPOINT = process.env.REACT_APP_NEXT_PUBLIC_TABLET_BREAKPOINT
export const GRECAPTCHA_SITE_KEY = process.env.REACT_APP_NEXT_PUBLIC_GRECAPTCHA_SITE_KEY
export const GRECAPTCHA_SECRET_KEY = process.env.REACT_APP_GRECAPTCHA_SECRET_KEY
export const GRECAPTCHA_VALIDATION_URL = process.env.REACT_APP_NEXT_PUBLIC_GRECAPTCHA_VALIDATION_URL
export const PRISMIC_ENDPOINT = process.env.REACT_APP_PRISMIC_ENDPOINT
export const GOOGLEMAPS_API_KEY = process.env.REACT_APP_NEXT_PUBLIC_GOOGLEMAPS_API_KEY
export const NEXT_PUBLIC_HUBSPOT_PORTAL_ID = process.env.REACT_APP_NEXT_PUBLIC_HUBSPOT_PORTAL_ID
export const EMAILJS_PUBLIC_KEY = process.env.REACT_APP_EMAILJS_PUBLIC_KEY

