import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { Navigation } from '../Navigation'
import { HeroSection } from './components/HeroSection'
import { Footer } from '../Footer'
import { SimplestSolution } from '../SimplestSolution'
import { Stack } from '../common/components/Spacing'
import { Testimonials } from '../Testimonials'
import { CustomerCards } from './components/CustomerCards'
import { MWrapper } from '../common/styled'
import { useIsMobile } from '../common/hooks'

export const Customers = () => {
  const { locale } = useParams<string>()
  const isMobile = useIsMobile()
  const [hasHeroSectionAppeared, setHasHeroSectionAppeared] = useState(false)
  const [customerCases, setCustomerCases] = useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const customerCasesData = await prismicClient.getAllByType('customer_case', {
        lang: prismicLocale,
      })
      setCustomerCases(customerCasesData)
    }
    getPrismicData()
  }, [locale])
  return (
    <div>
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <HeroSection setHeroSectionAppeared={setHasHeroSectionAppeared} />
        {
          (hasHeroSectionAppeared || isMobile) && (
            <Stack gap={50}>
              <CustomerCards hasHeroSectionAppeared={hasHeroSectionAppeared} customerCases={customerCases} />
              <Testimonials />
              <SimplestSolution shouldStartAnimation={hasHeroSectionAppeared} />
              <Footer />
            </Stack>
          )
        }
      </MWrapper>
    </div>
  )
}
