import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Stack } from '../../common/components/Spacing'
import { Description, SubTitle } from '../../common/styled'
import { Button } from '../../common/components/Buttons'
import { ComponentVariants, DESKTOP_ANIMATION_SHIFT, Sizes } from '../../common/constants'
import { useBookDemoModal } from '../../../context'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import { useWindowHeight } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'
import { getCurrentLocale } from '../../../config/locales'
import { prismicClient } from '../../../config/prismicClient'
import { useParams } from 'react-router-dom'

type WrapperProps = {
  isMobile: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw`w-[900px] mx-auto`}
  ${({ isMobile }) => isMobile && tw`w-[100%]`}
`
const ContactusDescription = tw(Description)`text-sm`
const CtaButton = tw(Button)`max-w-max`

export const ContactUs = () => {
  const { locale } = useParams()
  const { setBookDemoModalOpen } = useBookDemoModal()

  const isMobile = useIsMobile()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const [contactUsRef, contactUsInView] = useInView({
    threshold: 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const controls = useAnimation()
  const [prismicData, setPrismicData] = React.useState<any>(null)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const footerData =
        await prismicClient.getSingle('contact_us', {
          lang: prismicLocale,
        })
      setPrismicData(footerData)
    }

    getPrismicData()
  }, [locale])

  useEffect(() => {
    if (contactUsInView) {
      if (isMobile) {
        controls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
        return
      }

      controls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [isMobile, contactUsInView])


  if (!prismicData) {
    return null
  }
  return (
    <Wrapper
      animate={controls}
      isMobile={!!isMobile}
      ref={contactUsRef}
      initial={isMobile ? { opacity: 0 } : { opacity: 0, y: -DESKTOP_ANIMATION_SHIFT }}
    >
      <Stack gap={7.5}>
        <SubTitle isMobile={isMobile}>{prismicData.data.contact_header[0]?.text}</SubTitle>
        <ContactusDescription isMobile={isMobile}>{prismicData.data.contact_description[0]?.text}</ContactusDescription>
        <CtaButton
          variant={ComponentVariants.Primary}
          size={Sizes.Small}
          onClick={() => {
            setBookDemoModalOpen(true)
          }}
        >
          {prismicData.data.contact_cta[0]?.text}
        </CtaButton>
      </Stack>
    </Wrapper>
  )
}
