export const getCardTitleById = (id: string, customerCases: any) => {
  const customerCase = customerCases?.find((cCase: any) => cCase.id === id)

  return customerCase?.data?.case_headline?.[0]?.text ?? ''
}

export const getCardCta = (id: string, customerCases: any) => {
  const customerCase = customerCases?.find((cCase: any) => cCase.id === id)

  return customerCase?.data?.shortcut_cta?.[0]?.text ?? ''
}