import React, { useEffect } from 'react'
import { motion, useAnimation, useScroll, useTransform } from 'framer-motion'
import tw from 'twin.macro'
import { useVerticalScrollWithThrottle } from '../../common/hooks'

const Wrapper = tw(motion.div)`mt-[100px]`
const Img = tw(motion.img)`w-full rounded-[20px]`

const IMAGE_HEIGHT = 550
const MOBILE_IMAGE_SCALE_START = 1
const MOBILE_IMAGE_SCALE_END = 0.3
const FRACTION = 0.2
const HEIGHT_DIFFERENCE =
  IMAGE_HEIGHT * MOBILE_IMAGE_SCALE_START - IMAGE_HEIGHT * MOBILE_IMAGE_SCALE_END
const Y_OFFSET_MAX = -HEIGHT_DIFFERENCE * FRACTION

const MOBILE_MARGIN_BOTTOM_START = 0
const MOBILE_MARGIN_BOTTOM_END = -180

const SCALE_SPEED_COEFFICIENT = 0.8

type MobileImageProps = {
  imgUrl: string
}
export const MobileImage = ({ imgUrl }: MobileImageProps) => {

  const { scrollY } = useVerticalScrollWithThrottle()
  const { scrollY: imgScrollY } = useScroll()

  const yOffset = useTransform(imgScrollY, [0, 2500], [0, Y_OFFSET_MAX], { clamp: false })
  const scale = useTransform(
    imgScrollY,
    [0, 100],
    [MOBILE_IMAGE_SCALE_START, MOBILE_IMAGE_SCALE_END],
    { clamp: false },
  )

  const marginBottom = useTransform(
    imgScrollY,
    [0, 100],
    [0, -IMAGE_HEIGHT * (MOBILE_IMAGE_SCALE_START - MOBILE_IMAGE_SCALE_END) * SCALE_SPEED_COEFFICIENT],
    { clamp: false },
  )
  const wrapperControls = useAnimation()
  const imageControls = useAnimation()

  useEffect(() => {
    const sequence = async () => {
      await imageControls.start({
        scale: scale.get() > MOBILE_IMAGE_SCALE_END ? scale.get() : MOBILE_IMAGE_SCALE_END,
        translateY: yOffset.get(),
        marginBottom: marginBottom.get() > MOBILE_MARGIN_BOTTOM_END ? marginBottom.get() : MOBILE_MARGIN_BOTTOM_END,
      })
    }
    sequence()
  }, [imageControls, scrollY])

  useEffect(() => {
    const sequence = async () => {
      await wrapperControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
    }
    sequence()
  }, [wrapperControls])
  return (
    <Wrapper animate={wrapperControls} initial={{ opacity: 0 }}>
      <Img animate={imageControls} initial={{ scale: MOBILE_IMAGE_SCALE_START, marginBottom: MOBILE_MARGIN_BOTTOM_START }} src={imgUrl} />
    </Wrapper>
  )
}