import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Navigation } from '../Navigation'
import { HeroSection } from './components/HeroSection'
import tw, { styled } from 'twin.macro'
import { SimplestSolution } from '../SimplestSolution'
import { Footer } from '../Footer'
import { ZevoyMap } from './components/ZevoyMap'
import { Testimonials } from '../Testimonials'
import { useIsMobile } from '../common/hooks'
import { MWrapper } from '../common/styled'
import { useAnimation, motion } from 'framer-motion'

import { DESKTOP_ANIMATION_SHIFT } from '../common/constants'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { MetaTags } from '../common/components/MetaTags'

type LandingVideoProps = {
  isMobile: boolean
}
const LandingVideo = styled.video<LandingVideoProps>`
  ${tw`max-w-full h-auto -mt-[100px]`}
  ${({ isMobile }) => isMobile && tw`-mt-[40px] mb-[60px] max-w-[180%] ml-[-40%]`}
`
const TestimonialWrapper = tw.div` mt-[160px] mb-[100px]`

export const Home = () => {
  const { locale } = useParams<string>()
  const isMobile = useIsMobile()
  const [prismicDataMeta, setPrismicMetaData] = useState<any>(null)
  const [prismicData, setPrismicData] = useState<any>(null)

  const heroUnderlineControls = useAnimation()
  const heroWrapperControls = useAnimation()
  const heroMobileTitleControls = useAnimation()
  const heroMobileUnderlineControls = useAnimation()
  const heroMobileDescriptionControls = useAnimation()

  const videoControls = useAnimation()

  const [showSimplestSolution, setShowSimplestSolution] = useState<boolean>(false)

  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '');
      const prismicLocale = localeData.prismicLocale;

      const homePageMeta = await prismicClient.getSingle('home-page', {
        lang: prismicLocale,
      });

      const homePage = await prismicClient.getSingle('home_page', {
        lang: prismicLocale,
      });

      setPrismicData(homePage);
      setPrismicMetaData(homePageMeta);
    }
      getPrismicData()
  }, [locale])

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await heroMobileTitleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await heroMobileDescriptionControls.start({ opacity: 1, transition: { duration: 0.3 } })
      heroMobileUnderlineControls.start({
        clipPath: 'inset(0 0% 0 0)',
        transition: { duration: 0.3 },
      })
      videoControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.2 } })
      setTimeout(() => {
        setShowSimplestSolution(true)
      }, 200)
    }

    const desktopHeroSequence = async () => {
      await heroWrapperControls.start({
        opacity: 1,
        y: 0,
        transition: { duration: 0.5, delay: 0.3 },
      })
      heroUnderlineControls.start({
        clipPath: 'inset(0 0% 0 0)',
        transition: { duration: 0.3, ease: 'linear' },
      })
      videoControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } })
      setTimeout(() => {
        setShowSimplestSolution(true)
      }, 200)
    }

    if (!prismicData) return
    if (isMobile) {
      mobileHeroSequence()
    } else {
      desktopHeroSequence()
    }
  }, [isMobile, prismicData])

  return (
    <div>
      {/*<MetaTags*/}
      {/*  title={prismicDataMeta?.share_title?.[0]?.text}*/}
      {/*  description={prismicDataMeta?.data?.share_text[0]?.text}*/}
      {/*  imageUrl={prismicDataMeta?.data?.share_image.url}*/}
      {/*  imageWidth={prismicDataMeta?.data?.share_image?.dimensions?.width}*/}
      {/*  imageHeight={prismicDataMeta?.data?.share_image?.dimensions?.height}*/}
      {/*/>*/}
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <HeroSection
          mobileTitleControls={heroMobileTitleControls}
          mobileUnderlineControls={heroMobileUnderlineControls}
          mobileDescriptionControls={heroMobileDescriptionControls}
          wrapperControls={heroWrapperControls}
          underlineControls={heroUnderlineControls}
          translations={{
            heroTitle: prismicData?.data?.home_header[0]?.text,
            heroDescription: prismicData?.data?.home_description[0]?.text,
            heroCta: prismicData?.data?.home_cta[0]?.text,
          }}
        />
        <motion.div animate={videoControls} initial={{ opacity: 0, y: DESKTOP_ANIMATION_SHIFT }}>
          <LandingVideo muted playsInline autoPlay loop preload={'auto'} isMobile={!!isMobile}>
            <source
              src="/videos/web_anim_v3_H265_MP4.mp4"
              type='video/mp4; codecs="hvc1, avc1.42E01E, mp4a.40.2"'
            />
            <source src="/videos/web_anim_2180_ALPHA.webm" type="video/webm" />
          </LandingVideo>
        </motion.div>
        <SimplestSolution shouldStartAnimation={showSimplestSolution} />
        <TestimonialWrapper>
          <Testimonials />
        </TestimonialWrapper>
        <ZevoyMap translations={{
          title: prismicData?.data?.map_header[0]?.text,
          highlighted: prismicData?.data?.map_header_color[0]?.text,
        }} />
        <Footer />
      </MWrapper>
    </div>
  )
}
