import React, { useEffect } from 'react'
import { Stack } from '../../common/components/Spacing'
import { Alignments, ComponentVariants, CUSTOMER_CARD_HEIGHT, CUSTOMER_CARD_WIDTH, Sizes } from '../../common/constants'
import { NavLink } from 'react-router-dom'
import tw, { styled } from 'twin.macro'
import { Button } from '../../common/components/Buttons'
import { useIsMobile } from '../../common/hooks'
import { AnimationControls, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

type WrapperProps = {
  imgUrl: string
  withTopMargin?: boolean
  isMobile?: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw`rounded-[20px]`}
  width: ${CUSTOMER_CARD_WIDTH}px;
  height: ${CUSTOMER_CARD_HEIGHT}px;
  background: url(${({ imgUrl }: WrapperProps) => imgUrl});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${({ withTopMargin }: WrapperProps) => withTopMargin && tw`mt-[60px]`}
  ${({ isMobile }: WrapperProps) => isMobile && tw`mt-0`}
`

const Mask = styled.div`
    ${tw`bg-black bg-opacity-35 h-[550px] absolute rounded-[20px]`}
    width: ${CUSTOMER_CARD_WIDTH}px;
`
const OnMask = tw.div`z-10`
const CustomerButton = tw(Button)`text-center mb-[30px]`

type CustomerCardProps = {
  title: React.ReactNode
  imgUrl: string
  link: string
  logo: React.ReactNode | null
  index: number
  controls?: AnimationControls
  readyToAppear?: boolean
  delay?: number
  translations: {
    readMore: string
  }
  threshold?: number
}
export const CustomerCard = ({
  title,
  imgUrl,
  link,
  logo,
  index,
  controls,
  readyToAppear,
  delay = 0.3,
  translations,
  threshold = 0.2,
}: CustomerCardProps) => {
  const isMobile = useIsMobile()

  const [tileRef, tileInView] = useInView({
    threshold,
  })

  useEffect(() => {
    if (tileInView) {
      if (isMobile && controls && tileInView) {
        controls.start({
          opacity: 1,
          transition: { duration: 0.3, delay },
          y: 0,
        })
      }
    }
  }, [tileInView, controls, isMobile])

  useEffect(() => {
    let isMounted = true
    const sequence = async () => {
      if (!isMounted) {
        return
      }
      await controls?.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: delay || 0 } })
    }
    if (isMobile) {
      return
    }

    if (readyToAppear && controls) {
      sequence()
    }

    return () => {
      isMounted = false
    }

  }, [readyToAppear, isMobile, controls])

  if (isMobile === undefined) {
    return null
  }

  return (
    <Wrapper
      animate={controls}
      ref={tileRef}
      initial={{ opacity: 0, y: isMobile ? 70 : 100 }}
      imgUrl={imgUrl}
      withTopMargin={!isMobile && index !== 1 && index !== 4 && index !== 7 && index !== 10 && index !== 13 }>
      <Mask />
      <Stack verticalAlign={Alignments.Between} width="350px">
        <OnMask>
          <Stack gap={7.5} align={Alignments.Center}>
            {logo ?? null}
            {title}
          </Stack>
        </OnMask>
        <Stack align={Alignments.Center}>
          <OnMask>
            <NavLink to={link}>
              <CustomerButton variant={ComponentVariants.Primary} size={Sizes.Small}>
                {translations.readMore}
              </CustomerButton>
            </NavLink>
          </OnMask>
        </Stack>
      </Stack>
    </Wrapper>
  )
}
