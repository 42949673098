import React, { useEffect } from 'react'
import tw, { styled, theme } from 'twin.macro'

import { ReactComponent as BackIcon } from '../../../../assets/icons/back.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg'
import { motion, useAnimation } from 'framer-motion'
import { useWindowHeight } from '@react-hook/window-size'
import { CLOSE_MODAL } from './constants'

const Backdrop = styled(motion.div)`
  ${tw`fixed top-0 left-0 right-0 bottom-0 z-50 opacity-0`}
  background: rgba(10, 22, 46, 0.10);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
`

type ModalContainerProps = {
  isMobile: boolean
}
const ModalContainer = styled(motion.div)<ModalContainerProps>`
  ${tw`fixed z-50 opacity-0`}
  width: 390px;
  padding: 60px 0;
  border-radius: 20px;
  background: ${theme('colors.zevoyBlueBlack')};
  box-shadow: 0 0 40px 0 rgba(255, 255, 255, 0.05);
  ${({ isMobile }: ModalContainerProps) => isMobile && tw`w-full left-0`}
  ${({ isMobile }: ModalContainerProps) =>
    !isMobile &&
    `
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`

const Back = styled(BackIcon)`
  ${tw`absolute top-0 left-0 mt-[20px] ml-[20px] cursor-pointer w-[24px] h-[24px] transition-all duration-300 opacity-100 hover:opacity-75`}
  fill: ${theme('colors.zevoyWhite')};
`

const Close = styled(CloseIcon)`
  ${tw`absolute top-0 right-0 mt-[20px] mr-[20px] cursor-pointer w-[24px] h-[24px] transition-all duration-300 opacity-100 hover:opacity-75`}
  fill: ${theme('colors.zevoyWhite')};
`

type ModalProps = {
  children: React.ReactNode
  onClose: () => void
  onBack?: () => void
  isMobile?: boolean
}

export const Modal: React.FC<ModalProps> = ({ children, onClose, onBack, isMobile }) => {
  const containerControls = useAnimation()
  const backdropControls = useAnimation()
  const windowHeight = useWindowHeight()

  useEffect(() => {
    const desktopSequence = () => {
      containerControls.start({ opacity: 1, transition: { duration: 0.3 } })
      backdropControls.start({ opacity: 1, transition: { duration: 0.3 } })
    }

    const mobileSequence = () => {
      containerControls.start({ opacity: 1, bottom: 0, transition: { duration: 0.3 } })
      backdropControls.start({ opacity: 1, transition: { duration: 0.3 } })
    }
    if (isMobile) {
      mobileSequence()
      return
    }

    desktopSequence()
  }, [containerControls, isMobile])

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = originalStyle
    }
  }, [])

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keydown', handleEscape)

    return () => {
      window.removeEventListener('keydown', handleEscape)
    }
  }, [])

  const closeModal = async () => {
    await Promise.all([
      containerControls.start({
        opacity: 0,
        bottom: isMobile ? -windowHeight : 'initial',
        transition: { duration: 0.3 },
      }),
      backdropControls.start({ opacity: 0, transition: { duration: 0.3 } }),
    ])
    onClose()
  }

  useEffect(() => {
    document.addEventListener(CLOSE_MODAL, closeModal)
    return () => {
      document.removeEventListener(CLOSE_MODAL, closeModal)
    }
  }, [onClose])

  return (
    <>
      <Backdrop onClick={closeModal} animate={backdropControls} initial={{ opacity: 0 }} />
      <ModalContainer
        animate={containerControls}
        isMobile={!!isMobile}
        initial={{ opacity: 0, bottom: isMobile ? -windowHeight : 'initial' }}
      >
        {onBack && <Back onClick={onBack} />}
        <Close onClick={closeModal} />
        {children}
      </ModalContainer>
    </>
  )
}
