import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Stack } from '../../common/components/Spacing'
import { Description, SubTitle } from '../../common/styled'
import { Button } from '../../common/components/Buttons'
import { ComponentVariants, DESKTOP_ANIMATION_SHIFT, Sizes } from '../../common/constants'
import { NavLink, useParams } from 'react-router-dom'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useWindowHeight } from '@react-hook/window-size'

type WrapperProps = {
  isMobile: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw` w-[900px] mx-auto`}
  ${({ isMobile }) => isMobile && tw`w-[100%]`}
`

const TableItem = tw.div`flex flex-row justify-between items-start pb-5 border-0 border-b  border-solid border-zevoyGray1`

const PricingDescription = styled(Description)`
  ${tw`text-sm`}
  ${({ isMobile }) => isMobile && tw`w-[182px]`}
`

const PricingDescriptionRight = styled(Description)`
  ${tw`text-sm`}
  ${({ isMobile }) => isMobile && tw`text-[12px] w-[120px]`}
`
const PricingDescriptionBottom = styled(Description)`
  ${tw`text-sm`}
  ${({ isMobile }) => isMobile && tw`text-[10px]`}
`

type TransparentPricingProps = {
  translations: {
    title: string
    description: string
    option1: string
    price1: string
    option2: string
    price2: string
    option3: string
    price3: string
    active_user_disclaimer: string
    cta: string
  }
}
export const TransparentPricing = ({ translations }: TransparentPricingProps) => {
  const { locale } = useParams<string>()
  const isMobile = useIsMobile()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const [pricingRef, pricingInView] = useInView({
    threshold: 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const wrapperControls = useAnimation()

  useEffect(() => {
    if (pricingInView) {
      if (isMobile) {
        wrapperControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
        return
      }
      wrapperControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [isMobile, wrapperControls, pricingInView])

  return (
    <Wrapper
      animate={wrapperControls}
      ref={pricingRef}
      initial={isMobile ? { opacity: 0 } : { opacity: 0, y: -DESKTOP_ANIMATION_SHIFT }}
      isMobile={!!isMobile}
    >
      <Stack gap={7.5}>
        <SubTitle isMobile={isMobile}>{translations.title}</SubTitle>
        <Description isMobile={isMobile}>{translations.description}</Description>
        <Stack gap={5}>
          <TableItem>
            <PricingDescription isMobile={isMobile}>{translations.option1}</PricingDescription>
            <PricingDescriptionRight isMobile={isMobile}>
              {translations.price1}
            </PricingDescriptionRight>
          </TableItem>
          <TableItem>
            <PricingDescription isMobile={isMobile}>{translations.option2}</PricingDescription>
            <PricingDescriptionRight isMobile={isMobile}>
              {translations.price2}
            </PricingDescriptionRight>
          </TableItem>
          <TableItem>
            <PricingDescription isMobile={isMobile}>{translations.option3}</PricingDescription>
            <PricingDescriptionRight isMobile={isMobile}>
              {translations.price3}
            </PricingDescriptionRight>
          </TableItem>
        </Stack>
        <PricingDescriptionBottom isMobile={isMobile}>
          {translations.active_user_disclaimer}
        </PricingDescriptionBottom>
        <NavLink to={`/${locale}/pricing`}>
          <Button variant={ComponentVariants.Light} size={Sizes.Small}>
            {translations.cta}
          </Button>
        </NavLink>
      </Stack>
    </Wrapper>
  )
}
