import tw, { css } from 'twin.macro'
import { ComponentVariants } from '../../constants'
import { ButtonVariants } from './Buttons'
import { Interpolation } from 'styled-components'

const primary = css`
  ${tw`
    bg-brightOrange text-white border-brightOrange
    hover:bg-brightOrangeHover
    disabled:opacity-30
    disabled:shadow-none
    disabled:hover:bg-brightOrange
  `}
`

const disabledPrimary = css`
  ${tw`
    bg-brightOrange text-white border-brightOrange
    opacity-30
    hover:cursor-not-allowed
  `}
`

const secondary = css`
  ${tw`
    bg-white text-zevoyBlue border-zevoyBlue
    hover:opacity-70
    disabled:bg-white
    disabled:text-zevoyGray4
    disabled:border-zevoyGray4
    disabled:hover:bg-white
  `}
`

const light = css`
  ${tw`
    bg-transparent text-white border-white hover:text-opacity-75 hover:border-opacity-75
  `}
`

const borderless = css`
  ${tw`
    bg-transparent text-white border-transparent hover:text-opacity-75 hover:border-opacity-75
  `}
`

const dark = css`
  ${tw`
    bg-zevoyBlueBlack text-white border-white hover:text-opacity-75 hover:border-opacity-75
  `}
`

const disabledSecondary = css`
  ${tw`
    bg-white text-zevoyGray4 border-zevoyGray4
    hover:cursor-not-allowed
  `}
`

const grey = css`
  ${tw`
    bg-zevoyGray2 text-white border-none
    hover:opacity-70
  `}
`

export const ButtonVariantStyles: Partial<Record<ButtonVariants, Interpolation<object>>> = {
  [ComponentVariants.Primary]: primary,
  [ComponentVariants.DisabledPrimary]: disabledPrimary,
  [ComponentVariants.Secondary]: secondary,
  [ComponentVariants.DisabledSecondary]: disabledSecondary,
  [ComponentVariants.Borderless]: borderless,
  [ComponentVariants.Light]: light,
  [ComponentVariants.Dark]: dark,
  [ComponentVariants.Grey]: grey,
}
