import * as React from 'react'
import tw, { styled, css } from 'twin.macro'
import { ButtonSizeStyles, StretchedButtonSizeStyles } from './ButtonSize'
import { ButtonVariantStyles } from './ButtonVariant'
import { ComponentVariants, Sizes } from '../../constants'

export type ButtonVariants = Extract<
  ComponentVariants,
  | ComponentVariants.Primary
  | ComponentVariants.Secondary
  | ComponentVariants.DisabledPrimary
  | ComponentVariants.DisabledSecondary
  | ComponentVariants.Light
  | ComponentVariants.Borderless
  | ComponentVariants.Dark
  | ComponentVariants.Grey
>
export interface StyledButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isStretched?: boolean
  size?: Sizes
  variant?: ButtonVariants
  width?: string
  notClickable?: boolean
}

const StyledButton = styled('button')<StyledButtonProps>`
  cursor: pointer;
  ${tw`
    flex
    flex-row
    rounded-md
    shadow-none
    border
    border-solid
    text-center
    items-center
    justify-center
    gap-2
    disabled:hover:cursor-not-allowed
  `}
  ${tw`transition-colors duration-200 ease-in-out`}
  ${({ size, isStretched }) =>
    size && (isStretched ? StretchedButtonSizeStyles[size] : ButtonSizeStyles[size])};
  ${({ variant }) => variant && ButtonVariantStyles[variant]};
  ${({ width }) =>
    !!width &&
    css`
      width: ${width};
    `}
  ${({ notClickable }) =>
    notClickable &&
    css`
      pointer-events: none;
    `}
`

interface ButtonProps extends StyledButtonProps {
  buttonRef?: React.RefObject<HTMLButtonElement>
  children: React.ReactNode
}
export const Button = ({
  buttonRef,
  children,
  size,
  variant,
  width,
  notClickable,
  isStretched,
  ...props
}: ButtonProps) => {
  return (
    <StyledButton
      ref={buttonRef}
      size={size ?? Sizes.Large}
      isStretched={isStretched}
      variant={variant ?? ComponentVariants.Primary}
      width={width}
      notClickable={notClickable}
      {...props}
      onClick={(e) => {
        if (notClickable) {
          e.preventDefault()
          e.stopPropagation()
        }
        props.onClick && props.onClick(e)
      }}
    >
      {children}
    </StyledButton>
  )
}
