import React, { useEffect } from 'react'
import { useIsMobile } from '../common/hooks'

type UseInitialNavigationAnimationProps = {
  isMenuOpen: boolean
  mobileOverlayControls: any
  mobileHomeLinkControls: any
  mobileProductLinkControls: any
  mobileExpensesLinkControls: any
  mobileSubscriptionsLinkControls: any
  mobileClaimsLinkControls: any
  mobileBenefitsLinkControls: any
  mobileForMerchantsLinkControls: any
  mobilePricingLinkControls: any
  mobileCustomersLinkControls: any
  mobileContactLinkControls: any
  mobileBottomSectionControls: any
}

export const useInitialNavigationAnimation = ({
  isMenuOpen,
  mobileOverlayControls,
  mobileHomeLinkControls,
  mobileProductLinkControls,
  mobileExpensesLinkControls,
  mobileSubscriptionsLinkControls,
  mobileClaimsLinkControls,
  mobileBenefitsLinkControls,
  mobileForMerchantsLinkControls,
  mobilePricingLinkControls,
  mobileCustomersLinkControls,
  mobileContactLinkControls,
  mobileBottomSectionControls
}: UseInitialNavigationAnimationProps) => {
  const isMobile = useIsMobile()

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileOverlayControls.start({ opacity: 1, transition: { duration: 0.2 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileOverlayControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileHomeLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.1 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileHomeLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileProductLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.2 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileProductLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileExpensesLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.3 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileExpensesLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileSubscriptionsLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.4 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileSubscriptionsLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileClaimsLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.5 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileClaimsLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileBenefitsLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.6 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileBenefitsLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileForMerchantsLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.7 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileForMerchantsLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobilePricingLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.8 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobilePricingLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileCustomersLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 0.9 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileCustomersLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileContactLinkControls.start({ opacity: 1, transition: { duration: 0.1, delay: 1 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileContactLinkControls.stop()
    }
  }, [isMobile, isMenuOpen])

  useEffect(() => {
    let isMounted = true;

    const mobileHeroSequence = async () => {
      if (isMounted) {
        await mobileBottomSectionControls.start({ opacity: 1, transition: { duration: 0.1, delay: 1.1 } })
      }
    }
    if (isMobile && isMenuOpen) {
      mobileHeroSequence()
    }
    return () => {
      isMounted = false;
      mobileBottomSectionControls.stop()
    }
  }, [isMobile, isMenuOpen])
}