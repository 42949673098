import React, { useEffect, useState } from 'react'
import { useIsMobile } from '../../common/hooks'
import tw, { styled } from 'twin.macro'
import { Inline, Stack } from '../../common/components/Spacing'
import { Alignments, DESKTOP_ANIMATION_SHIFT } from '../../common/constants'
import { Description, SubTitle, TextBig } from '../../common/styled'
import { motion, useAnimation } from 'framer-motion'
import { useWindowHeight } from '@react-hook/window-size'
import { useInView } from 'react-intersection-observer'

const Wrapper =  styled(motion.div)`
  ${tw`w-[1055px] mx-auto`}
`
const MerchantItem = tw.div`w-[325px] text-center`
const MerchantItemTitle = tw(TextBig)`text-[48px] leading-[110%] h-[110px] flex items-center justify-center`
const MerchantItemDescription = tw.div`text-[18px]`

const MobileWrapper = tw.div``
const MobileSubTitle = tw(SubTitle)`text-[32px] leading-[110.5%]`
const MobileDescription = tw(Description)`text-[14px]`

type MerchantItemsProps = {
  cardsReady: boolean
  translations: {
    items: {
      title: string
      description: string
    }[]
  }

}
export const MerchantItems = ({ translations, cardsReady }: MerchantItemsProps) => {
  const isMobile = useIsMobile()

  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const [itemsRef, itemsInView] = useInView({
    threshold: 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const desktopControls = useAnimation()

  const [title1Ref, title1InView] = useInView({
    threshold: 0.3,
  })
  const title1Controls = useAnimation()
  const description1Controls = useAnimation()

  const [title2Ref, title2InView] = useInView({
    threshold: 0.3,
  })
  const title2Controls = useAnimation()
  const description2Controls = useAnimation()

  const [title3Ref, title3InView] = useInView({
    threshold: 0.3,
  })
  const title3Controls = useAnimation()
  const description3Controls = useAnimation()

  const [firstItemReady, setFirstItemReady] = useState(false)
  const [secondItemReady, setSecondItemReady] = useState(false)

  useEffect(() => {
    const sequence = async () => {
      await title1Controls.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } })
      await description1Controls.start({ opacity: 1, y: 0, transition: { duration: 0.3 } })
      setFirstItemReady(true)
    }
    if (!isMobile || !cardsReady || !title1InView) return

    sequence()
  }, [isMobile, cardsReady, title1InView])

  useEffect(() => {
    const sequence = async () => {
      await title2Controls.start({ opacity: 1, y: 0, transition: { duration: 0.3 } })
      await description2Controls.start({ opacity: 1, y: 0, transition: { duration: 0.3 } })
      setSecondItemReady(true)
    }
    if (!isMobile || !cardsReady || !title2InView || !firstItemReady) return

    sequence()
  }, [isMobile, cardsReady, title2InView, firstItemReady])

  useEffect(() => {
    const sequence = async () => {
      await title3Controls.start({ opacity: 1, y: 0, transition: { duration: 0.3 } })
      await description3Controls.start({ opacity: 1, y: 0, transition: { duration: 0.3 } })
    }
    if (!isMobile || !cardsReady || !title3InView || !firstItemReady || !secondItemReady) return

    sequence()
  }, [isMobile, cardsReady, title3InView, firstItemReady, secondItemReady])

  useEffect(() => {
    if (itemsInView && cardsReady && !isMobile) {
      desktopControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [isMobile, itemsInView, cardsReady])

  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack gap={15}>
          <Stack gap={5}>
            <MobileSubTitle ref={title1Ref} animate={title1Controls} initial={{ opacity: 0 }}>
              {translations.items[0].title}
            </MobileSubTitle>
            <MobileDescription animate={description1Controls} initial={{ opacity: 0 }}>
              {translations.items[0].description}
            </MobileDescription>
          </Stack>
          <Stack gap={5}>
            <MobileSubTitle ref={title2Ref} animate={title2Controls} initial={{ opacity: 0 }}>
              {translations.items[1].title}
            </MobileSubTitle>
            <MobileDescription animate={description2Controls} initial={{ opacity: 0 }}>
              {translations.items[1].description}
            </MobileDescription>
          </Stack>
          <Stack gap={5}>
            <MobileSubTitle ref={title3Ref} animate={title3Controls} initial={{ opacity: 0 }}>
              {translations.items[2].title}
            </MobileSubTitle>
            <MobileDescription animate={description3Controls} initial={{ opacity: 0 }}>
              {translations.items[2].description}
            </MobileDescription>
          </Stack>
        </Stack>
      </MobileWrapper>
    )
  }
  return (
    <Wrapper animate={desktopControls} ref={itemsRef} initial={{ opacity: 0, y: -DESKTOP_ANIMATION_SHIFT}}>
      <Inline align={Alignments.Between} verticalalign={Alignments.Start}>
        {translations.items.map((item, index) => (
          <MerchantItem key={index}>
            <Stack gap={7.5}>
              <MerchantItemTitle>{item.title}</MerchantItemTitle>
              <MerchantItemDescription>{item.description}</MerchantItemDescription>
            </Stack>
          </MerchantItem>
        ))}
      </Inline>
    </Wrapper>
  )
}