import tw from 'twin.macro'
import { NavLink, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Inline, Stack } from '../../common/components/Spacing'
import {
  Alignments,
  ComponentVariants,
  DESKTOP_ANIMATION_SHIFT,
  Sizes,
} from '../../common/constants'
import { Button } from '../../common/components/Buttons'
import { HeroTitle } from '../../common/styled'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'

const Wrapper = tw(motion.div)`mt-[250px] text-center w-[800px] mx-auto relative z-10 `

const HeroDescription = tw.h2`text-xl text-[20px] font-light m-0`
const HeroButtons = tw.div``

const MobileWrapper = tw.div`relative z-10 mt-[125px]`
const MobileHeroTitle = tw(HeroTitle)`text-[40px] leading-[105%]`
const MobileDescription = tw(HeroDescription)`text-[14px] leading-[120%]`

type HeroSectionProps = {
  setShowSimplestSolution?: (value: boolean) => void
  translations: {
    heroTitle: string
    heroDescription: string
    heroCta: string

  }
}

export const HeroSection = ({ setShowSimplestSolution, translations }: HeroSectionProps) => {
  const { locale } = useParams()

  const isMobile = useIsMobile()

  const mobileTitleControls = useAnimation()
  const mobileDescriptionControls = useAnimation()

  const desktopControls = useAnimation()

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await mobileTitleControls.start({ opacity: 1, transition: { duration: 0.3, delay: 0.3 } })
      await mobileDescriptionControls.start({ opacity: 1, transition: { duration: 0.3 } })
      setShowSimplestSolution?.(true)
    }

    const desktopHeroSequence = async () => {
      await desktopControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
      setShowSimplestSolution?.(true)
    }
    if (isMobile) {
      mobileHeroSequence()
    } else {
      desktopHeroSequence()
    }
  }, [isMobile])

  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack align={Alignments.Left} gap={4}>
          <motion.div animate={mobileTitleControls} initial={{ opacity: 0 }}>
            <MobileHeroTitle>{translations.heroTitle}</MobileHeroTitle>
          </motion.div>
          <motion.div animate={mobileDescriptionControls} initial={{ opacity: 0 }}>
            <Stack gap={10}>
              <MobileDescription>{translations.heroDescription}</MobileDescription>
              <HeroButtons>
                <Inline align={Alignments.Left}>
                  <NavLink to={`/${locale}/contact`}>
                    <Button variant={ComponentVariants.Primary} size={Sizes.Medium}>
                      {translations.heroCta}
                    </Button>
                  </NavLink>
                </Inline>
              </HeroButtons>
            </Stack>
          </motion.div>
        </Stack>
      </MobileWrapper>
    )
  }

  return (
    <Wrapper animate={desktopControls} initial={{ y: DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
      <Stack align={Alignments.Center} gap={10}>
        <HeroTitle>{translations.heroTitle}</HeroTitle>
        <Stack gap={7.5}>
          <HeroDescription>{translations.heroDescription}</HeroDescription>
          <HeroButtons>
            <Inline align={Alignments.Center}>
              <NavLink to={`/${locale}/contact`}>
                <Button variant={ComponentVariants.Primary} size={Sizes.Medium}>
                  {translations.heroCta}
                </Button>
              </NavLink>
            </Inline>
          </HeroButtons>
        </Stack>
      </Stack>
    </Wrapper>
  )
}
