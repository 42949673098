import React, { useEffect, useMemo, useRef, useState } from 'react'
import tw, { styled } from 'twin.macro'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useIsMobile, useRelativeScrollPosition, useVerticalScrollWithThrottle } from '../../common/hooks'
import { useWindowHeight } from '@react-hook/window-size'
import { Inline, Stack } from '../../common/components/Spacing'
import { NavLink, useParams } from 'react-router-dom'
import { Button } from '../../common/components/Buttons'
import {
  Alignments,
  ComponentVariants,
  CustomerCasesContent,
  Sizes,
} from '../../common/constants'
import { CustomerCard } from '../../Customers/components/CustomerCard'
import {
  AlisaPankkiLogo,
  AlisaPankkiTitle, GasmetTechnologiesLogo,
  GasmetTechnologiesTitle, UprightProjectLogo,
  UprightProjectTitle, VainuLogo,
  VainuTitle,
} from '../../Customers/components/CustomerCards'
import { getCardCta, getCardTitleById } from '../../Customers/utils'

const Wrapper = tw.div``

const Tiles = tw(motion.div)`flex flex-wrap justify-center items-center gap-10 items-end`
const ViewAllWrapper = tw.div`mt-[20px] mb-[40px]`

// Mobile
const START_POSITION = 0
const END_POSITION = -770

const MobileWrapper = styled(motion.div)`
  transform: translateX(${START_POSITION}px);
  position: static;
`

type CustomerCardsProps = {
  havePrerequisitesAppeared: boolean
  otherCustomerCases: any[]
  translations: {
    viewAllCases: string
  }
}
export const OtherCustomerCases = ({ havePrerequisitesAppeared, otherCustomerCases, translations }: CustomerCardsProps) => {
  const isMobile = useIsMobile()
  const { locale, customer } = useParams()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`

  // Mobile
  const mobileRef = useRef<HTMLElement | null>(null)
  const [appeared, setAppeared] = useState(false)
  const mobileControls = useAnimation()
  const { scrollY } = useVerticalScrollWithThrottle()
  const relativeScrollY = useRelativeScrollPosition(mobileRef.current)


  const [initialOffsetY, setInitialOffsetY] = useState<undefined | number>(undefined)

  // Desktop
  const [tilesRef, tilesInView] = useInView({
    threshold: 0,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })

  const firstTileControls = useAnimation()
  const secondTileControls = useAnimation()
  const thirdTileControls = useAnimation()

  const viewAllControls = useAnimation()

  const bottomOffsetRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const desktopSequence = async () => {
      await firstTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3, delay: 0.3 } })
      await secondTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3 } })
      await thirdTileControls.start({ opacity: 1, y: 0, transition: { duration: 0.3 } })
      await viewAllControls.start({ opacity: 1, transition: { duration: 0.3 } })
    }

    if (isMobile) {
      return
    }

    if (tilesInView) {
      desktopSequence()
    }
  }, [tilesInView, firstTileControls, secondTileControls, isMobile])

  useEffect(() => {
    const sequence = async () => {
      if (!appeared ) {
        await mobileControls.start({
          opacity: 1,
          transition: { duration: 0.3, delay: 0.9 },
        })
        setAppeared(true)
        return
      }
    }

    if (isMobile && !!mobileRef.current && havePrerequisitesAppeared) {
      sequence()
    }
  }, [mobileControls, isMobile, scrollY, relativeScrollY, appeared, mobileRef.current, havePrerequisitesAppeared])

  useEffect(() => {
    if (!!mobileRef.current && havePrerequisitesAppeared) {
      setInitialOffsetY(mobileRef.current.offsetTop)
    }
  }, [mobileRef.current, customer, havePrerequisitesAppeared])

  // handle hub image horizontal and vertical movement
  // useCustomerCardsScrollAnimation({
  //   startPositionY: initialOffsetY === undefined ? undefined : initialOffsetY - (CUSTOMER_CARD_HEIGHT / 2 ) -40,
  //   mobileRef,
  //   startPositionX: START_POSITION,
  //   endPositionX: END_POSITION,
  //   speedMultiplier: 0.8,
  //   bottomOffsetRef
  // })

  const setRefs = (node: HTMLElement | null) => {
    mobileRef.current = node
  }

  const customerCards = useMemo(() => {
    const currentOtherCustomerCases = otherCustomerCases?.map((caseData: any) => {
      const otherCaseId =  Object.keys(CustomerCasesContent).find((key) =>
        CustomerCasesContent[key]?.prismicId[locale as 'en' | 'fi' | 'sv'] === caseData.id
      )

      return otherCaseId ? CustomerCasesContent[otherCaseId] : undefined
    }) ?? []

    // if one is undefined, dont show any items to not mess up the animations
    if (currentOtherCustomerCases?.includes(undefined)) {
      return []
    }

    return currentOtherCustomerCases.map((caseItem, index) => {
      const prisimicId = caseItem!.prismicId[locale as 'en' | 'fi' | 'sv']
      const cardTitle = getCardTitleById(prisimicId, otherCustomerCases)

      return {
        id: caseItem!.id,
        imgUrl: caseItem!.cardImage,
        title: caseItem!.id === 'alisa_pankki'
          ? <AlisaPankkiTitle>{cardTitle}</AlisaPankkiTitle>
          : caseItem!.id === 'vainu'
              ? <VainuTitle>{cardTitle}</VainuTitle>
              : caseItem!.id === 'upright_project'
                ? <UprightProjectTitle>{cardTitle}</UprightProjectTitle>
                : <GasmetTechnologiesTitle>{cardTitle}</GasmetTechnologiesTitle>,
        link: `/${locale}/customers/${caseItem!.id}`,
        logo: caseItem!.id === 'alisa_pankki'
          ? <AlisaPankkiLogo />
          : caseItem!.id === 'vainu'
              ? <VainuLogo />
              : caseItem!.id === 'upright_project'
                ? <UprightProjectLogo />
                : <GasmetTechnologiesLogo />,
        controls: index === 0
          ? firstTileControls
          : index === 1
            ? secondTileControls
            : thirdTileControls,
        cta: getCardCta(prisimicId, otherCustomerCases)
      }
    }) ?? []
}, [otherCustomerCases, locale, customer])

  if (isMobile) {
    return (
      <Stack>
        <Stack>
          <MobileWrapper initial={{ opacity: 0 }} animate={mobileControls} ref={setRefs}>
            <Stack gap={7.5}>
              {
                customerCards.map((card, index) => (
                  <CustomerCard
                    key={index}
                    imgUrl={card.imgUrl}
                    title={card.title}
                    link={card.link}
                    logo={card.logo}
                    index={index}
                    controls={card.controls}
                    translations={{ readMore: card.cta }}
                  />
                ))
              }
            </Stack>
          </MobileWrapper>
          <div ref={bottomOffsetRef} />
        </Stack>
        <Inline align={Alignments.Center}>
          <ViewAllWrapper>
            <NavLink to={`/${locale}/customers`}>
              <Button variant={ComponentVariants.Light} size={Sizes.Medium}>{translations.viewAllCases}</Button>
            </NavLink>
          </ViewAllWrapper>
        </Inline>
      </Stack>
    )
  }

  return (
    <Wrapper>
      <Tiles ref={tilesRef}>
        {
          customerCards.map((card, index) => (
            <CustomerCard
              key={index}
              imgUrl={card.imgUrl}
              title={card.title}
              link={card.link}
              logo={card.logo}
              index={index}
              controls={card.controls}
              translations={{ readMore: card.cta }}
            />
          ))
        }
      </Tiles>
      <motion.div
        animate={viewAllControls}
        initial={{ opacity: 0 }}
      >

        <Inline align={Alignments.Center}>
          <ViewAllWrapper>
            <NavLink to={`/${locale}/customers`}>
              <Button variant={ComponentVariants.Light} size={Sizes.Medium}>{translations.viewAllCases}</Button>
            </NavLink>
          </ViewAllWrapper>
        </Inline>
      </motion.div>
    </Wrapper>
  )
}
