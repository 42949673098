import React, { useEffect, useState } from 'react'
import tw, { styled } from 'twin.macro'
import { Inline, Stack } from '../common/components/Spacing'
import { Alignments, SolutionItemIds } from '../common/constants'
import { NavLink, useParams } from 'react-router-dom'

import { TextHighlight } from '../common/styled'
import { useIsMobile } from '../common/hooks'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { LocaleIndex } from '../common/types'

const Wrapper = tw.div`mt-[60px]`

const Item = tw.div`w-[292px]`
const Title = styled(TextHighlight)`
  font-size: 12px;
  font-family: 'Slussen-Medium', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
`
const Bold = styled.div`
  font-family: 'Slussen-Medium', sans-serif;
  font-weight: 500;
`
const VerySmall = tw.div`text-[11px] mb-[50px]`

const MobileWrapper = tw.div`mt-[100px]`
const MobileItem = tw.div`w-[165px]`
const MobileTitle = tw(Title)``

export const Footer = () => {
  const { locale } = useParams()
  const isMobile = useIsMobile()
  const [prismicData, setPrismicData] = useState<any>(null)
  useEffect(() => {
    const getPrismicData = async () => {
      const localeData = getCurrentLocale(locale ?? '')
      const prismicLocale = localeData.prismicLocale

      const footerData =
        await prismicClient.getSingle('footer_new', {
          lang: prismicLocale,
        })
      setPrismicData(footerData)
    }

    getPrismicData()
  }, [locale])

  if (!prismicData) {
    return null
  }

  const address = (
    <div>
      <Bold>{prismicData.data.company[0]?.text}</Bold>
      <div>{prismicData.data.address[0]?.text}</div>
      <div>{prismicData.data.city[0]?.text}</div>
      <div>{prismicData.data.country[0]?.text}</div>
      <div>
        {prismicData.data.business_id_header[0]?.text}
        {isMobile ? <br /> : ': '}
        {prismicData.data.business_id[0]?.text}
      </div>
    </div>
  )

  const contact = (
    <div>
      <div>{prismicData.data.sales_email[0]?.text}</div>
      <div>{prismicData.data.support_email[0]?.text}</div>
    </div>
  )

  const tsAndCs = (
    <div>
      <div>
        <a href="/docs/Zevoy-General-Terms-and-Conditions-20230301.pdf" target="_blank">
          {prismicData.data.terms_of_service[0]?.text}
        </a>
      </div>
      <div>
        <a href="/docs/Zevoy-Payment-Card-Terms-and-Conditions-20230301.pdf" target="_blank">
          {prismicData.data.terms_of_card[0]?.text}
        </a>
      </div>
      <div>
        <a href="/zevoy-privacy-policy.html" target="_blank">
          {prismicData.data.privacy_policy[0]?.text}
        </a>
      </div>
      <div>
        <a href="/docs/Zevoy-Cookie-Policy.pdf" target="_blank">
          {prismicData.data.cookie_policy[0]?.text}
        </a>
      </div>
      <div>
        <NavLink to={`/${locale}/pricing`}>{prismicData.data.pricing[0]?.text}</NavLink>
      </div>
    </div>
  )

  const followUs = (
    <div>
      <NavLink to="https://www.linkedin.com/company/zevoy/">{prismicData.data.linkedin[0]?.text}</NavLink>
    </div>
  )

  if (isMobile) {
    return (
      <MobileWrapper>
        <Stack gap={15}>
          <Inline verticalalign={Alignments.Start}>
            <MobileItem>
              <Stack gap={7.5}>
                <MobileTitle>{prismicData.data.address_header[0]?.text}</MobileTitle>
                {address}
              </Stack>
            </MobileItem>
            <MobileItem>
              <Stack gap={7.5}>
                <MobileTitle>{prismicData.data.contact_header[0]?.text}</MobileTitle>
                {contact}
              </Stack>
            </MobileItem>
          </Inline>
          <Inline verticalalign={Alignments.Start}>
            <MobileItem>
              <Stack gap={7.5}>
                <MobileTitle>{prismicData.data.t_cs_header[0]?.text}</MobileTitle>
                {tsAndCs}
              </Stack>
            </MobileItem>
            <MobileItem>
              <Stack gap={7.5}>
                <MobileTitle>{prismicData.data.follow_us_header[0]?.text}</MobileTitle>
                {followUs}
              </Stack>
            </MobileItem>
          </Inline>
          <VerySmall>{prismicData.data.header_description[0]?.text}</VerySmall>
        </Stack>
      </MobileWrapper>
    )
  }

  return (
    <Wrapper>
      <Stack gap={15}>
        <Inline align={Alignments.Between} verticalalign={Alignments.Start}>
          <Item>
            <Stack gap={7.5}>
              <Title>{prismicData.data.address_header[0]?.text}</Title>
              {address}
            </Stack>
          </Item>
          <Item>
            <Stack gap={7.5}>
              <Title>{prismicData.data.contact_header[0]?.text}</Title>
              {contact}
            </Stack>
          </Item>
          <Item>
            <Stack gap={7.5}>
              <Title>{prismicData.data.t_cs_header[0]?.text}</Title>
              {tsAndCs}
            </Stack>
          </Item>
          <Item>
            <Stack gap={7.5}>
              <Title>{prismicData.data.follow_us_header[0]?.text}</Title>
              {followUs}
            </Stack>
          </Item>
        </Inline>
        <VerySmall dangerouslySetInnerHTML={{ __html: prismicData.data.header_description[0]?.text?.replace(/\n/g, '<br />') }} />
      </Stack>
    </Wrapper>
  )
}
