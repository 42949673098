import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Inline } from '../../common/components/Spacing'
import { motion, useAnimation } from 'framer-motion'
import { useIsMobile } from '../../common/hooks'
import { Alignments, DESKTOP_ANIMATION_SHIFT } from '../../common/constants'

const Wrapper = tw(motion.div)``

type CardsProps = {
  $isMobile: boolean
}
const Cards = styled.img<CardsProps>`
  ${({ $isMobile }) => !$isMobile && tw`max-w-[645px]`}
  ${({ $isMobile }) => $isMobile && tw`w-[105%]`}
`

type MerchantCardsProps = {
  setCardsReady: (value: boolean) => void
}
export const MerchantCards = ({ setCardsReady }: MerchantCardsProps) => {
  const isMobile = useIsMobile()
  const controls = useAnimation()


  useEffect(() => {
    const mobileSequence = async () => {
      await controls.start({ opacity: 1, transition: { duration: 0.5, delay: 0.9 } })
      setCardsReady(true)

    }
    const desktopSequence = async () => {
      await controls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.5 } })
      setCardsReady(true)
    }

    if (isMobile) {
      mobileSequence()
    } else {
      desktopSequence()
    }
  }, [isMobile])
  return (
    <Wrapper animate={controls} initial={{ opacity: 0, y: DESKTOP_ANIMATION_SHIFT }}>
      <Inline gap={0} align={Alignments.Center}>
        <Cards $isMobile={!!isMobile} src="/images/merchant_cards.png" />
      </Inline>
    </Wrapper>
  )
}