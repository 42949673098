import React, { useEffect } from 'react'
import { useIsMobile } from '../../common/hooks'
import { useWindowHeight } from '@react-hook/window-size'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import tw, { styled } from 'twin.macro'
import { DESKTOP_ANIMATION_SHIFT } from '../../common/constants'
import { Stack } from '../../common/components/Spacing'
import { SubTitle } from '../../common/styled'
import { useParams } from 'react-router-dom'

type WrapperProps = {
  isMobile?: boolean
}
const Wrapper = styled.div<WrapperProps>`
    ${tw`w-[900px] mx-auto`}
    ${({ isMobile }) => isMobile && tw`w-[100%]`}
`
const Text = tw.div`text-[14px] leading-[160%]`

type ContentProps = {
  hasHeroSectionAppeared: boolean
  caseTranslation: {
    company: any
    challenge: any
    solution: any
    result: any
    titles: {
      company: string
      challenge: string
      solution: string
      result: string
    }
  }
}
export const Content = ({ caseTranslation, hasHeroSectionAppeared }: ContentProps) => {
  const { customer } = useParams()
  const isMobile = useIsMobile()
  const windowHeight = useWindowHeight()
  const appearanceRatio = isMobile ? `-${0.7 * windowHeight}px` : `-${0.8 * windowHeight}px`
  const companyControls = useAnimation()
  const challengeControls = useAnimation()
  const solutionControls = useAnimation()
  const resultControls = useAnimation()

  const [companyRef, companyInView] = useInView({
    threshold: 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [challengeRef, challengeInView] = useInView({
    threshold: 0.3,
    rootMargin:
      customer === 'gasmet_technologies' && (windowHeight < 600)
        ? `0px 0px 0px 0px`
        : `${appearanceRatio} 0px 0px 0px`,
  })
  const [solutionRef, solutionInView] = useInView({
    threshold: 0.3,
    rootMargin: `${appearanceRatio} 0px 0px 0px`,
  })
  const [resultRef, resultInView] = useInView({
    threshold: 0.3,
    rootMargin:
      customer === 'gasmet_technologies' && (isMobile || (windowHeight < 900))
        ? `0px 0px 0px 0px`
        : `${appearanceRatio} 0px 0px 0px`,
  })

  useEffect(() => {
    if (companyInView && hasHeroSectionAppeared) {
      companyControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [companyInView])

  useEffect(() => {
    if (challengeInView && hasHeroSectionAppeared) {

      challengeControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [challengeInView])

  useEffect(() => {
    if (solutionInView && hasHeroSectionAppeared) {
      solutionControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [solutionInView])

  useEffect(() => {
    if (resultInView && hasHeroSectionAppeared) {
      resultControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.3 } })
    }
  }, [resultInView])

  return (
    <Wrapper isMobile={isMobile}>
      <Stack gap={15}>
        <motion.div animate={companyControls} ref={companyRef} initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
          <Stack gap={5}>
            <SubTitle isMobile={isMobile}>{caseTranslation.titles.company}</SubTitle>
            {
              caseTranslation.company?.map((item: any, index: number) => (
                  <Text key={index}>{item.text}</Text>
              )) ?? null
            }
          </Stack>
        </motion.div>
        <motion.div animate={challengeControls} ref={challengeRef} initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
          <Stack gap={5}>
            <SubTitle isMobile={isMobile}>{caseTranslation.titles.challenge}</SubTitle>
            {
              caseTranslation.challenge?.map((item: any, index: number) => (
                  <Text key={index}>{item.text}</Text>
              )) ?? null
            }
          </Stack>
        </motion.div>
        <motion.div animate={solutionControls} ref={solutionRef} initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
          <Stack gap={5}>
            <SubTitle isMobile={isMobile}>{caseTranslation.titles.solution}</SubTitle>
            {
              caseTranslation.solution?.map((item: any, index: number) => (
                  <Text key={index}>{item.text}</Text>
              )) ?? null
            }
          </Stack>
        </motion.div>
        <motion.div animate={resultControls} ref={resultRef} initial={{ y: -DESKTOP_ANIMATION_SHIFT, opacity: 0 }}>
          <Stack gap={5}>
            <SubTitle isMobile={isMobile}>{caseTranslation.titles.result}</SubTitle>
            {
              caseTranslation.result?.map((item: any, index: number) => (
                  <Text key={index}>{item.text}</Text>
              )) ?? null
            }
          </Stack>
        </motion.div>
      </Stack>
    </Wrapper>
)
}