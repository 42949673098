import tw, { styled, theme } from 'twin.macro'
import { motion } from 'framer-motion'

export const TextBig = styled(motion.div)`
  ${tw`-tracking-[1.5px] font-medium leading-[93%]`}
  font-family: "Slussen-Medium", sans-serif;
`

export const TextHighlight = styled(motion.span)`
  background: linear-gradient(
    90deg,
    ${theme('colors.electricViolet')},
    ${theme('colors.zevoyOrange')}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const HeroTitle = styled(motion.h1)`
  ${tw`text-[84px] font-medium -tracking-[1.5px] m-0 leading-[93%]`}
  font-family: "Slussen-Medium", sans-serif;
`

type SubTitleProps = {
  isMobile?: boolean
}
export const SubTitle = styled(motion.h2)<SubTitleProps>`
  ${tw`text-[48px] font-medium -tracking-[1.5px] m-0 leading-[1.2]`}
  font-family: "Slussen-Medium", sans-serif;
  ${({ isMobile }: SubTitleProps) => isMobile && tw`text-[32px] leading-[110.5%]`}
`

export const MiniTitle = styled.h3`
  ${tw`text-[24px] font-medium -tracking-[1.5px] m-0 leading-[110.5%]`}
  font-family: "Slussen-Medium", sans-serif;
`

type DescriptionProps = {
  isMobile?: boolean
}
export const Description = styled(motion.div)<DescriptionProps>`
  ${tw`m-0 text-[18px] leading-normal`}
  ${({ isMobile }: DescriptionProps) => isMobile && tw`text-[14px] leading-normal`}
`

export const Apostrophe = tw.span`text-[10px] inline-block align-top`

export type CarouselIndicatorProps = {
  $isSelected?: boolean
  $isWhite?: boolean
}
export const CarouselIndicator = styled(motion.span)<CarouselIndicatorProps>`
  ${tw`bg-zevoyGray2 w-[10px] h-[10px] inline-block rounded-full`}
`

export const CarouselIndicatorNormal = styled(CarouselIndicator)<CarouselIndicatorProps>`
  ${tw`transition-all duration-500 ease-in-out`}
  ${({ $isSelected }: CarouselIndicatorProps) => $isSelected && tw`w-[70px]`}
    ${({ $isWhite }: CarouselIndicatorProps) => $isWhite && tw`bg-zevoyWhite`}
`

export const CarouselIndicatorActive = styled(CarouselIndicator)<CarouselIndicatorProps>`
  ${tw`bg-zevoyWhite w-[10px] absolute top-[6px] left-0`}
`

type MWrapperProps = {
  $isMobile: boolean | undefined
}
export const MWrapper = styled.div<MWrapperProps>`
  ${({ $isMobile }) => $isMobile && tw`mx-auto w-[350px]`}
`
