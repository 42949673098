import { useEffect, useLayoutEffect, useState } from 'react'
import { useWindowHeight } from '@react-hook/window-size'
import { useScroll, useTransform } from 'framer-motion'
import throttle from 'lodash.throttle'
import { useNavigate } from 'react-router-dom'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined)

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1200px)')
    const handleMediaQueryChange = (e: MediaQueryListEvent) => {
      setIsMobile(e.matches)
    }

    setIsMobile(mediaQuery.matches)

    mediaQuery.addEventListener('change', handleMediaQueryChange)

    return () => mediaQuery.removeEventListener('change', handleMediaQueryChange)
  }, [])

  return isMobile
}

export const useVerticalScroll = () => {
  const [scrollY, setScrollY] = useState(window.scrollY)

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return scrollY
}

export const useVerticalScrollWithThrottle = () => {
  const [scrollY, setScrollY] = useState(window.scrollY)

  useEffect(() => {
    const handleScroll = throttle(() => {
      setScrollY(window.scrollY)
    }, 5)

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      handleScroll.cancel()
    }
  }, [])

  return { scrollY, setScrollY }
}

export const useCalculateVisibleHeight = () => {
  const windowHeight = useWindowHeight()

  const calculateVisibleHeight = (element: HTMLElement | null): number => {
    if (!element) {
      return 0
    }
    const rect = element.getBoundingClientRect()

    if (rect.top >= 0 && rect.bottom <= windowHeight) {
      return rect.height
    }

    if (rect.top < 0 && rect.bottom > 0) {
      return rect.bottom
    }

    if (rect.top < windowHeight && rect.bottom > windowHeight) {
      return windowHeight - rect.top
    }

    return 0
  }

  return calculateVisibleHeight
}

export const useDynamicYOffsetMax = (element: HTMLElement | null, fraction = 0.2) => {
  const [yOffsetMax, setYOffsetMax] = useState(0)

  useEffect(() => {
    const updateYOffsetMax = () => {
      if (element) {
        const height = element.offsetHeight
        const newYOffsetMax = -height * fraction
        setYOffsetMax(newYOffsetMax)
      }
    }

    updateYOffsetMax()

    const resizeObserver = new ResizeObserver(updateYOffsetMax)
    if (element) {
      resizeObserver.observe(element)
    }

    return () => {
      if (element) {
        resizeObserver.unobserve(element)
      }
    }
  }, [element, fraction])

  return yOffsetMax
}

export const useDynamicScrollTransform = (
  element: HTMLElement | null,
  startScale: number,
  endScale: number,
) => {
  const [inputRange, setInputRange] = useState([0, 1])
  const { scrollY } = useScroll()
  const windowHeight = useWindowHeight()

  const value = useTransform(scrollY, inputRange, [startScale, endScale])

  useEffect(() => {
    const calculateAndSetRange = () => {
      if (element) {
        const rect = element.getBoundingClientRect()
        const start = window.scrollY + rect.top - windowHeight
        const end = window.scrollY + rect.bottom
        setInputRange([start >= 0 ? start : 0, end / 2])
      }
    }

    calculateAndSetRange()

    const handleEvent = () => calculateAndSetRange()
    window.addEventListener('resize', handleEvent)
    window.addEventListener('scroll', handleEvent)

    return () => {
      window.removeEventListener('resize', handleEvent)
      window.removeEventListener('scroll', handleEvent)
    }
  }, [element])

  return value
}

export const useRelativeScrollPosition = (element: HTMLElement | null) => {
  const [relativeScrollPosition, setRelativeScrollPosition] = useState(0)
  const { scrollY } = useScroll()

  useEffect(() => {
    const updatePosition = () => {
      if (element) {
        const viewportHeight = window.innerHeight
        const rect = element.getBoundingClientRect()
        const relativePosition = rect.top - viewportHeight + rect.height

        setRelativeScrollPosition(relativePosition)
      }
    }

    window.addEventListener('scroll', updatePosition)
    window.addEventListener('resize', updatePosition)

    updatePosition()

    return () => {
      window.removeEventListener('scroll', updatePosition)
      window.removeEventListener('resize', updatePosition)
    }
  }, [element, scrollY])

  return relativeScrollPosition
}
