import React from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Alignments } from '../../constants'

type WrapperProps = {
  align?: Alignments
  verticalalign?: Alignments
  fullWidth?: boolean
  fullHeight?: boolean
  isWrapped?: boolean
  gap?: number
}

type InlineProps = React.PropsWithChildren<WrapperProps>

const Wrapper = styled.div<WrapperProps>`
  ${tw`flex flex-row items-center gap-4`}
  ${({ align }) =>
    align === Alignments.Left
      ? tw`justify-start`
      : align === Alignments.Center
        ? tw`justify-center`
        : align === Alignments.Right
          ? tw`justify-end`
          : align === Alignments.Between
            ? tw`justify-between`
            : tw`justify-start`}
  ${({ verticalalign }) =>
    verticalalign &&
    (verticalalign === Alignments.Start
      ? tw`items-start`
      : verticalalign === Alignments.Center
        ? tw`items-center`
        : verticalalign === Alignments.End
          ? tw`items-end`
          : tw`items-start`)}
  ${({ fullWidth }) => fullWidth && tw`w-full`}
  ${({ fullHeight }) => fullHeight && tw`h-full`}
  ${({ isWrapped }) => isWrapped && tw`flex-wrap`}
  ${({ gap }) =>
    (gap || gap === 0) &&
    css`
    gap: calc(${theme`spacing.1`} * ${gap});
  }
  `}
`
export const Inline = ({
  children,
  align = Alignments.Left,
  verticalalign,
  isWrapped,
  fullWidth,
  fullHeight,
  gap,
}: InlineProps) => {
  return (
    <Wrapper
      align={align}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      verticalalign={verticalalign}
      isWrapped={isWrapped}
      gap={gap}
    >
      {children}
    </Wrapper>
  )
}
