import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getCurrentLocale } from '../../config/locales'
import { prismicClient } from '../../config/prismicClient'
import { Navigation } from '../Navigation'
import { ContactUs } from '../Expenses/components/ContactUs'
import { ThingsToKnow } from '../Expenses/components/ThingsToKnow'
import { SimplestSolution } from '../SimplestSolution'
import { HeroSection } from './components/HeroSection'
import { PricingTable } from './components/PricingTable'
import { Stack } from '../common/components/Spacing'
import { Footer } from '../Footer'
import { MWrapper } from '../common/styled'
import { useIsMobile } from '../common/hooks'

export const Pricing = () => {
  const { locale } = useParams<string>()
  const isMobile = useIsMobile()
  const [showSimplestSolution, setShowSimplestSolution] = React.useState<boolean>(false)
  const [prismicData, setPrismicData] = useState<any>(null)

  useEffect(() => {
      const getPrismicData = async () => {
          const localeData = getCurrentLocale(locale ?? '');
          const prismicLocale = localeData.prismicLocale;

          const pricingData = await prismicClient.getSingle('pricing_page', {
              lang: prismicLocale,
          });
          setPrismicData(pricingData);
      }
      getPrismicData()
  }, [locale])
  return (
    <div>
      <Navigation />
      <MWrapper $isMobile={isMobile}>
        <Stack gap={25}>
          <HeroSection
            setShowSimplestSolution={setShowSimplestSolution}
            translations={{
              heroTitle: prismicData?.data?.pricing_headline[0]?.text,
              heroDescription: prismicData?.data?.pricing_introduction[0]?.text,
              heroCta: prismicData?.data?.pricing_cta[0]?.text,
            }}
          />
          <PricingTable
            translations={{
              title: prismicData?.data?.pricing_table_headline[0]?.text,
              description: prismicData?.data?.pricing_table_description[0]?.text,
              table1_header: prismicData?.data?.table_1_headline[0]?.text,
              table1_option1: prismicData?.data?.table1_option1[0]?.text,
              table1_price1: prismicData?.data?.table1_price1[0]?.text,
              table1_option2: prismicData?.data?.table1_option2[0]?.text,
              table1_price2: prismicData?.data?.table1_price2[0]?.text,
              table1_option3: prismicData?.data?.table1_option3[0]?.text,
              table1_price3: prismicData?.data?.table1_price3[0]?.text,
              table1_option4: prismicData?.data?.table1_option4[0]?.text,
              table1_price4: prismicData?.data?.table1_price4[0]?.text,
              table2_header: prismicData?.data?.table_2_headline[0]?.text,
              table2_option1: prismicData?.data?.table2_option1[0]?.text,
              table2_price1: prismicData?.data?.table2_price1[0]?.text,
              table2_option2: prismicData?.data?.table2_option2[0]?.text,
              table2_price2: prismicData?.data?.table2_price2[0]?.text,
              table2_option3: prismicData?.data?.table2_option3[0]?.text,
              table2_price3: prismicData?.data?.table2_price3[0]?.text,
              table3_header: prismicData?.data?.table_3_headline[0]?.text,
              table3_option0: prismicData?.data?.table3_option0[0]?.text,
              table3_price0: prismicData?.data?.table3_price0[0]?.text,
              table3_option1: prismicData?.data?.table3_option1[0]?.text,
              table3_price1: prismicData?.data?.table3_price1[0]?.text,
              table3_option2: prismicData?.data?.table3_option2[0]?.text,
              table3_price2: prismicData?.data?.table3_price2[0]?.text,
              table3_option3: prismicData?.data?.table3_option3[0]?.text,
              table3_price3: prismicData?.data?.table3_price3[0]?.text,
              table3_option4: prismicData?.data?.table3_option4[0]?.text,
              table3_price4: prismicData?.data?.table3_price4[0]?.text,
              table3_option5: prismicData?.data?.table3_option5[0]?.text,
              table3_price5: prismicData?.data?.table3_price5[0]?.text,
              table4_header: prismicData?.data?.table_4_headline[0]?.text,
              table4_option1: prismicData?.data?.table4_option1[0]?.text,
              table4_price1: prismicData?.data?.table4_price1[0]?.text,
              table4_option2: prismicData?.data?.table4_option2[0]?.text,
              table4_price2: prismicData?.data?.table4_price2[0]?.text,
              table4_option3: prismicData?.data?.table4_option3[0]?.text,
              table4_price3: prismicData?.data?.table4_price3[0]?.text,
              disclaimer1: prismicData?.data?.disclaimer_1[0]?.text,
              disclaimer2: prismicData?.data?.disclaimer_2[0]?.text,
              disclaimer3: prismicData?.data?.disclaimer_3[0]?.text,
              disclaimer4: prismicData?.data?.disclaimer_4[0]?.text,
              disclaimer5: prismicData?.data?.disclaimer_5[0]?.text,
              disclaimer6: prismicData?.data?.disclaimer_6[0]?.text,
            }}
          />
          <ContactUs />
          <ThingsToKnow />
          <SimplestSolution shouldStartAnimation={showSimplestSolution} />
        </Stack>
        <Footer />
      </MWrapper>
    </div>
  )
}
