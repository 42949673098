import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { Stack } from '../../common/components/Spacing'
import { Apostrophe, Description, MiniTitle, SubTitle } from '../../common/styled'
import { useIsMobile } from '../../common/hooks'
import { motion, useAnimation } from 'framer-motion'

type WrapperProps = {
  isMobile: boolean
}
const Wrapper = styled(motion.div)<WrapperProps>`
  ${tw`w-[900px] mx-auto`}
  ${({ isMobile }) => isMobile && tw`w-[100%]`}
`
const TableItem = tw.div`flex flex-row justify-between items-start pb-5 border-0 border-b  border-solid border-zevoyGray1`
const PricingDescription = tw(Description)`text-sm`
const Price = tw(PricingDescription)`flex-shrink-0 ml-[20px]`
const TableTitle = tw(MiniTitle)`mb-[10px]`

type PricingTableProps = {
  translations: {
    title: string
    description: string
    table1_header: string
    table1_option1: string
    table1_price1: string
    table1_option2: string
    table1_price2: string
    table1_option3: string
    table1_price3: string
    table1_option4: string
    table1_price4: string
    table2_header: string
    table2_option1: string
    table2_price1: string
    table2_option2: string
    table2_price2: string
    table2_option3: string
    table2_price3: string
    table3_header: string
    table3_option0: string
    table3_price0: string
    table3_option1: string
    table3_price1: string
    table3_option2: string
    table3_price2: string
    table3_option3: string
    table3_price3: string
    table3_option4: string
    table3_price4: string
    table3_option5: string
    table3_price5: string
    table4_header: string
    table4_option1: string
    table4_price1: string
    table4_option2: string
    table4_price2: string
    table4_option3: string
    table4_price3: string
    disclaimer1: string
    disclaimer2: string
    disclaimer3: string
    disclaimer4: string
    disclaimer5: string
    disclaimer6: string
  }
}

export const PricingTable = ({ translations }: PricingTableProps) => {
  const isMobile = useIsMobile()

  const desktopControls = useAnimation()
  const mobileDescriptionControls = useAnimation()

  useEffect(() => {
    const mobileHeroSequence = async () => {
      await mobileDescriptionControls.start({
        opacity: 1,
        transition: { duration: 0.3, delay: 0.9 },
      })
    }
    if (isMobile) {
      mobileHeroSequence()
    } else {
      desktopControls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.6 } })
    }
  }, [isMobile])
  return (
    <Wrapper
      isMobile={!!isMobile}
      animate={isMobile ? mobileDescriptionControls : desktopControls}
      initial={{ opacity: 0, y: isMobile ? 0 : -24 }}
    >
      <Stack gap={7.5}>
        <Stack gap={4}>
          <SubTitle isMobile={isMobile}>{translations.title}</SubTitle>
          <Description isMobile={isMobile}>{translations.description}</Description>
        </Stack>
        <TableTitle>{translations.table1_header}</TableTitle>
        <Stack gap={5}>
          <TableItem>
            <PricingDescription>{translations.table1_option1}</PricingDescription>
            <Price>{translations.table1_price1}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>{translations.table1_option2}</PricingDescription>
            <Price>{translations.table1_price2}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>{translations.table1_option3}<Apostrophe>1</Apostrophe></PricingDescription>
            <Price>{translations.table1_price3}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>{translations.table1_option4}</PricingDescription>
            <Price>{translations.table1_price4}</Price>
          </TableItem>
        </Stack>
        <TableTitle>{translations.table2_header}</TableTitle>
        <Stack gap={5}>
          <TableItem>
            <PricingDescription>{translations.table2_option1}</PricingDescription>
            <Price>{translations.table2_price1}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>{translations.table2_option2}</PricingDescription>
            <Price>{translations.table2_price2}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>
              {translations.table2_option3}
              <Apostrophe>2</Apostrophe>
            </PricingDescription>
            <Price>{translations.table2_price3}</Price>
          </TableItem>
        </Stack>
        <TableTitle>{translations.table3_header}</TableTitle>
        <Stack gap={5}>
          <TableItem>
            <PricingDescription>{translations.table3_option0}</PricingDescription>
            <Price>{translations.table3_price0}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>
              {translations.table3_option1}
              <Apostrophe>3</Apostrophe>
            </PricingDescription>
            <Price>{translations.table3_price1}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>
              {translations.table3_option2}
              <Apostrophe>4</Apostrophe>
            </PricingDescription>
            <Price>{translations.table3_price2}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>{translations.table3_option3}</PricingDescription>
            <Price>{translations.table3_price3}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>
              {translations.table3_option4}
              <Apostrophe>5</Apostrophe>
            </PricingDescription>
            <Price>{translations.table3_price4}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>
              {translations.table3_option5}
              <Apostrophe>6</Apostrophe>
            </PricingDescription>
            <Price>{translations.table3_price5}</Price>
          </TableItem>
        </Stack>
        <TableTitle>{translations.table4_header}</TableTitle>
        <Stack gap={5}>
          <TableItem>
            <PricingDescription>{translations.table4_option1}</PricingDescription>
            <Price>{translations.table4_price1}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>{translations.table4_option2}</PricingDescription>
            <Price>{translations.table4_price2}</Price>
          </TableItem>
          <TableItem>
            <PricingDescription>{translations.table4_option3}</PricingDescription>
            <Price>{translations.table4_price3}</Price>
          </TableItem>
        </Stack>
        <PricingDescription><Apostrophe>1</Apostrophe> {translations.disclaimer1}</PricingDescription>
        <PricingDescription>
          <Apostrophe>2</Apostrophe> {translations.disclaimer2}
        </PricingDescription>
        <PricingDescription>
          <Apostrophe>3</Apostrophe> {translations.disclaimer3}
        </PricingDescription>
        <PricingDescription>
          <Apostrophe>4</Apostrophe> {translations.disclaimer4}
        </PricingDescription>
        <PricingDescription>
          <Apostrophe>5</Apostrophe> {translations.disclaimer5}
        </PricingDescription>
        <PricingDescription>
          <Apostrophe>6</Apostrophe> {translations.disclaimer6}
        </PricingDescription>
      </Stack>
    </Wrapper>
  )
}
